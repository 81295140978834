<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      

      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >

      <div class="card card-custom mb-4">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-6">
              <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
                <span>
                  {{ title }}
                </span>
              </h5>
            </div>

            <div class="col-md-6 d-flex justify-content-end pb-2">
              <div>
                <b-button type="submit" variant="primary">Simpan Perubahan</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- start media register -->   
      <!-- <div class="card card-custom mb-4">
        <div class="card-body "> -->
     
        <!-- start upload media register-->
        <!-- <div class="row m-1">
           <div class="col-md-12">
            <span class="h4">Registration Image</span>
          </div>
           <div class="col-md-3">
              <img src="/img/screenshoot/Register.png" alt="" class="img-prev">
          </div>
          <div class="col-md-9 py-0">
              <b-form-group
                id="input-group-center-room"
                label="Display Registration Image:"
                label-for="input-center-room"
              >
              <div class="row">
                <div class="col-md-12 pb-0 mb-0">
                  <b-form-file
                    multiple
                    drop-placeholder="Lepaskan File disini..."
                    @change="uploadRegister"
                  ></b-form-file>
                </div>

                <div class="col-md-12 mt-0 pt-0">
                  <small class="text-muted ">Ukuran: 1024 x 683px</small>
                </div>
                
                <div
                  class="col-md-9 pb-0 pt-0 mb-0"  
                >
                
                <div class="row">
                  <div class="col-md-4"
                    v-for="(attachmentData,index) in fileData.register_image"
                    :key="attachmentData"
                  >
                    <b-card
                      class="m-1 shadow-sm border-primary"
                      body-class="px-4 py-4"
                    >
                      
                      <b-card-body class="d-flex justify-content-center p-1" >
                        <div class="row justify-content-end">
                          <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                            <img :src="fileData.register_image[index].file_url" alt="" style="max-width:85%" class="m-2">
                            <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.register_image[index].file_ext === 'pdf' || fileData.register_image[index].file_ext === 'docx' || fileData.register_image[index].file_ext === 'xlxs'"></i>
                          </div>
                          
                          <div class="col-md-2 col-4 d-inline">
                            <span style="cursor: pointer" @click="deleteRegister(index)"
                              >X</span
                            >
                          </div>
                        </div>
                      </b-card-body>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <span>{{fileData.register_image[index].file_name}}</span>
                          </div>
                        </div>
                    </b-card>
                  </div>
                </div>

                </div>
              </div>
              </b-form-group>
          </div>
        </div> -->
        <!-- end upload media register-->

        <!-- </div>
      </div> -->
      <!-- end media register -->

      <!-- start menu ads media -->   
      <!-- <div class="card card-custom mb-4">
        <div class="card-body "> -->
     
        <!-- start upload menu ads media-->
        <!-- <div class="row m-1">
          <div class="col-md-12">
            <span class="h4">Menu Ads Media</span>
          </div>
           <div class="col-md-3">
              <img src="/img/screenshoot/Menu-ads.png" alt="" class="img-prev">
          </div>
          <div class="col-md-9 py-0">
              <b-form-group
                id="input-group-center-room"
                label="Display Menu Ads Media:"
                label-for="input-center-room"
              >
              <div class="row">
                <div class="col-md-12 pb-0 mb-0">
                  <b-form-file
                    multiple
                    drop-placeholder="Lepaskan File disini..."
                    @change="uploadMenuAdsMedia"
                  ></b-form-file>
                </div>

                <div class="col-md-12 mt-0 pt-0">
                  <small class="text-muted ">Ukuran: 320 x 640px</small>
                </div>
                
                <div
                  class="col-md-9 pb-0 pt-0 mb-0"  
                >
                
                <div class="row">
                  <div class="col-md-4"
                    v-for="(attachmentData,index) in fileData.menu_ads_media"
                    :key="attachmentData"
                  >
                    <b-card
                      class="m-1 shadow-sm border-primary"
                      body-class="px-4 py-4"
                    >
                      
                      <b-card-body class="d-flex justify-content-center p-1" >
                        <div class="row justify-content-end">
                          <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                            <img :src="fileData.menu_ads_media[index].file_url" alt="" style="max-width:85%" class="m-2">
                            <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.menu_ads_media[index].file_ext === 'pdf' || fileData.menu_ads_media[index].file_ext === 'docx' || fileData.menu_ads_media[index].file_ext === 'xlxs'"></i>
                          </div>
                          
                          <div class="col-md-2 col-4 d-inline">
                            <span style="cursor: pointer" @click="deleteMenuAdsMedia(index)"
                              >X</span
                            >
                          </div>
                        </div>
                      </b-card-body>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <span>{{fileData.menu_ads_media[index].file_name}}</span>
                          </div>
                        </div>
                    </b-card>
                  </div>
                </div>

                </div>
              </div>
              </b-form-group>
          </div>
        </div> -->
        <!-- end upload menu ads media-->
<!-- 
        </div>
      </div> -->
      <!-- end menu ads media -->


      <!-- start media 1 -->   
      <!--start info media -->
      <div class="card card-custom mb-4">
        <div class="card-body bg-gray">
        <div class="row px-0 mx-0 mt-1">
          <div class="col-md-12">
            <span class="h4">Ruang Lobby Center</span>
          </div>

          <!-- <div class="col-md-6">
            <b-form-group>
              <label for="input-title-info-center-1">Judul Informasi:</label>
              <b-form-input
                id="input-title-info-center-1"
                v-model="form.center_room_media_1_url_title"
                placeholder="Judul Informasi"
              ></b-form-input>
            </b-form-group>
          </div> -->
          
          <!-- <div class="col-md-6">
            <b-form-group>
              <label for="input-subtitle-info-center-1">Sub-Judul Informasi:</label>
              <b-form-input
                id="input-subtitle-info-center-1"
                v-model="form.center_room_media_1_url_subtitle"
                placeholder="Sub-Judul Informasi"
              ></b-form-input>
            </b-form-group>
          </div> -->

          <!-- <div class="col-md-12">
            <label for="input-article"
              >Artikel: </label
            >
            <ckeditor
              id="input-article"
              class="border-ckEditor-muted"
              v-model="form.center_room_media_1_url_article"
              :editor="editor"
              :config="editorConfig"
              @ready="onReady"
            ></ckeditor>
          </div> -->

          <!-- <div class="col-md-12">
            <label for="input-article"
              >Gambar: </label
            >
              <b-form-file
                drop-placeholder="Lepaskan File disini..."
                @change="uploadInfoCenter1"
              ></b-form-file>
            <span class="text-teal" v-if="form.center_room_media_1_url_image_name != ''">Gambar saat ini : {{ form.center_room_media_1_url_image_name}}</span>
          </div> -->

           <!-- <div class="col-md-12">
            <b-form-group>
              <label for="input-site-url-info-center-1">Tautan Informasi atau Unduhan:</label>
              <b-form-input
                id="input-site-url-info-center-1"
                v-model="form.center_room_media_1_url_site_url"
                placeholder="Tautan Informasi atau Unduhan"
              ></b-form-input>
            </b-form-group>
          </div> -->
        </div>
        
        <!-- end info media -->

        <!-- start e-catalogue upload media -->
        <!-- <div class="row m-1">
          <div class="col-md-12 py-0">
              <b-form-group
                id="input-group-center-room"
                label="Display E-catalogue :"
                label-for="input-center-room"

              >
              <div class="row">
                <div class="col-md-12 pb-0 mb-0">
                  <b-form-file
                    multiple
                    drop-placeholder="Lepaskan File disini..."
                    @change="uploadEcatalogueCenter1"
                  ></b-form-file>
                </div>

                <div class="col-md-12 mt-0 pt-0">
                  <small class="text-muted ">Ukuran: 1280 x 720px</small>
                </div>
                
                <div
                  class="col-md-9 pb-0 pt-0 mb-0"  
                >
                
                <div class="row">
                  <div class="col-md-4"
                    v-for="(attachmentData,index) in fileData.center_room_media_1_url_e_catalogue"
                    :key="attachmentData"
                  >
                    <b-card
                      class="m-1 shadow-sm border-primary"
                      body-class="px-4 py-4"
                    >
                      
                      <b-card-body class="d-flex justify-content-center p-1" >
                        <div class="row justify-content-end">
                          <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                            <img :src="fileData.center_room_media_1_url_e_catalogue[index].file_url" alt="" style="max-width:85%" class="m-2">
                            <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.center_room_media_1_url_e_catalogue[index].file_ext === 'pdf' || fileData.center_room_media_1_url_e_catalogue[index].file_ext === 'docx' || fileData.center_room_media_1_url_e_catalogue[index].file_ext === 'xlxs'"></i>
                          </div>
                          
                          <div class="col-md-2 col-4 d-inline">
                            <span style="cursor: pointer" @click="deleteEcatalogueAttachCenter1(index)"
                              >X</span
                            >
                          </div>
                        </div>
                      </b-card-body>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <span>{{fileData.center_room_media_1_url_e_catalogue[index].file_name}}</span>
                          </div>
                        </div>
                    </b-card>
                  </div>
                </div>

                </div>
              </div>
              </b-form-group>
          </div>
        </div> -->
        <!-- end e-catalogue upload media -->

        <!-- start upload media -->
        <div class="row m-1">
          <div class="col-md-3">
              <img src="/img/screenshoot/Lobby-center.png" alt="" class="img-prev">
          </div>
          <div class="col-md-9">
              <b-form-group
                id="input-group-center-room"
                label="Display Media Lobby Center:"
                label-for="input-center-room"

              >
              <div class="row">
                <div class="col-md-9 pb-0 mb-0">
                  <b-form-file
                    ref="inputFile"
                    multiple
                    v-if="uploadType.center1 == 'file'"
                    drop-placeholder="Lepaskan File disini..."
                    @change="uploadCenter1"
                  ></b-form-file>

                  <b-form-input
                    v-if="uploadType.center1 == 'url'"
                    id="input-center-room"
                    v-model="form.center_room_media_1_url"
                    placeholder="Tautan Media"
                  ></b-form-input>
                </div>
                <div class="col-md-3 pb-0 mb-0">
                  <b-form-group>
                    <b-input-group>
                      <div class="input-group-prepend">
                        <b-button @click="btnUploadCenter1Link" class="btn btn-option" :variant="upload.centerLink">Tautan</b-button>
                      </div>
                      <div class="input-group-append">
                        <b-button @click="btnUploadCenter1Upload" class="btn btn-option" :variant="upload.centerUpload">Unggah</b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </div>

                <div class="col-md-12 mt-0 pt-0">
                  <small class="text-muted ">Ukuran: 1280 x 720px</small>
                </div>
                
                
                <div
                  class="col-md-12 pb-0 pt-0 mb-0"  
                >
                
                <div class="row">
                  <div class="col-md-4"
                    v-for="(attachmentDataEcatCenter1,index) in fileData.center_room_media_1_url"
                    :key="attachmentDataEcatCenter1"
                  >
                    <b-card
                      class="m-1 shadow-sm border-primary"
                      body-class="px-4 py-4"
                      v-if="form.center_room_media_1_url_type == 'file'"
                    >
                      
                      <b-card-body class="d-flex justify-content-center p-1" >
                        <div class="row justify-content-end">
                          <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                            <img :src="fileData.center_room_media_1_url[index].file_url" alt="" style="max-width:95%" class="m-2">
                            <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.center_room_media_1_url[index].file_ext === 'pdf' || fileData.center_room_media_1_url[index].file_ext === 'docx' || fileData.center_room_media_1_url[index].file_ext === 'xlxs'"></i>
                            <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.center_room_media_1_url[index].file_ext === 'mp4' || fileData.center_room_media_1_url[index].file_ext === 'm4v'"></i>
                            <i class="fas fa-file-audio fa-5x text-primary m-2" v-if="fileData.center_room_media_1_url[index].file_ext === 'mp3'"></i>
                          </div>
                          
                          <div class="col-md-2 col-4 d-inline">
                            <span style="cursor: pointer" @click="deleteAttachCenter1(index)"
                              >X</span
                            >
                          </div>
                        </div>
                      </b-card-body>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <span>{{fileData.center_room_media_1_url[index].file_name}}</span>
                          </div>
                        </div>
                    </b-card>
                  </div>
                </div>

                </div>
              </div>
                
                <small class="text-danger">{{ error.center_room_media_1_url }}</small>
              </b-form-group>
          </div>
        </div>
        <!-- end upload media -->
        </div>
      </div>
        <!-- end media 1 -->

        

        <!--start info media -->
      <div class="card card-custom mb-4">
        <div class="card-body ">
        <div class="row px-0 mx-0">
          <div class="col-md-12">
            <span class="h4">Video Ruang Hangout</span>
          </div>
          <!-- <div class="col-md-6">
            <b-form-group>
              <label for="input-title-info-center-2">Judul Informasi:</label>
              <b-form-input
                id="input-title-info-center-2"
                v-model="form.center_room_media_2_url_title"
                placeholder="Judul Informasi"
              ></b-form-input>
            </b-form-group>
          </div> -->
          
          <!-- <div class="col-md-6">
            <b-form-group>
              <label for="input-subtitle-info-center-2">Sub-Judul Informasi:</label>
              <b-form-input
                id="input-subtitle-info-center-2"
                v-model="form.center_room_media_2_url_subtitle"
                placeholder="Sub-Judul Informasi"
              ></b-form-input>
            </b-form-group>
          </div> -->

          <!-- <div class="col-md-12">
            <label for="input-article"
              >Artikel: </label
            >
            <ckeditor
              id="input-article"
              class="border-ckEditor-muted"
              v-model="form.center_room_media_2_url_article"
              :editor="editor"
              :config="editorConfig"
              @ready="onReady"
            ></ckeditor>
            
          </div> -->

          <!-- <div class="col-md-12">
            <label for="input-article"
              >Gambar: </label
            >
              <b-form-file
                drop-placeholder="Lepaskan File disini..."
                @change="uploadInfoCenter2"
              ></b-form-file>
              <span class="text-teal" v-if="form.center_room_media_2_url_image_name != ''">Gambar saat ini : {{ form.center_room_media_2_url_image_name}}</span>
          </div> -->

           <!-- <div class="col-md-12">
            <b-form-group>
              <label for="input-site-url-info-center-2">Tautan Informasi atau Unduhan:</label>
              <b-form-input
                id="input-site-url-info-center-2"
                v-model="form.center_room_media_2_url_site_url"
                placeholder="Tautan Informasi atau Unduhan"
              ></b-form-input>
            </b-form-group>
          </div> -->
        </div>
        <!-- end info media -->

        <!-- start e-catalogue upload media -->
        <!-- <div class="row m-1">
          <div class="col-md-12 py-0">
              <b-form-group
                id="input-group-center-room"
                label="Display E-Catalogue Lobby Officer:"
                label-for="input-center-room"

              >
              <div class="row">
                <div class="col-md-12 pb-0 mb-0">
                  <b-form-file
                    multiple
                    drop-placeholder="Lepaskan File disini..."
                    @change="uploadEcatalogueCenter2"
                  ></b-form-file>
                </div>

                <div class="col-md-12 mt-0 pt-0">
                  <small class="text-muted ">Ukuran: 1280 x 720px</small>
                </div>
                
                <div
                  class="col-md-9 pb-0 pt-0 mb-0"  
                >
                
                <div class="row">
                  <div class="col-md-3"
                    v-for="(attachmentDataEcatCenter2,index) in fileData.center_room_media_2_url_e_catalogue"
                    :key="attachmentDataEcatCenter2"
                  >
                    <b-card
                      class="m-1 shadow-sm border-primary"
                      body-class="px-4 py-4"
                    >
                      
                      <b-card-body class="d-flex justify-content-center p-1" >
                        <div class="row justify-content-end">
                          <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                            <img :src="fileData.center_room_media_2_url_e_catalogue[index].file_url" alt="" style="max-width:85%" class="m-2">
                            <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.center_room_media_2_url_e_catalogue[index].file_ext === 'pdf' || fileData.center_room_media_2_url_e_catalogue[index].file_ext === 'docx' || fileData.center_room_media_2_url_e_catalogue[index].file_ext === 'xlxs'"></i>
                          </div>
                          
                          <div class="col-md-2 col-4 d-inline">
                            <span style="cursor: pointer" @click="deleteEcatalogueAttachCenter2(index)"
                              >X</span
                            >
                          </div>
                        </div>
                      </b-card-body>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <span>{{fileData.center_room_media_2_url_e_catalogue[index].file_name}}</span>
                          </div>
                        </div>
                    </b-card>
                  </div>
                </div>

                </div>
              </div>
              </b-form-group>
          </div>
        </div> -->
        <!-- end e-catalogue upload media -->

        <!-- start media 2 -->
        <div class="row m-1">
          <div class="col-md-3">
            <img src="/img/screenshoot/Hangout-corner-video.png" alt="" class="img-prev">
          </div>
          <div class="col-md-9">
            <b-form-group
              id="input-group-center-room"
              label="Display Video Hangout Room:"
              label-for="input-center-room"

            >
            <div class="row">
              <div class="col-md-9 pb-0 mb-0">
                <b-form-file
                  ref="inputFile1"
                  multiple
                  v-if="uploadType.center2 == 'file'"
                  drop-placeholder="Lepaskan File disini..."
                  @change="uploadCenter2"
                ></b-form-file>

                <b-form-input
                  v-if="uploadType.center2 == 'url'"
                  id="input-center-room"
                  v-model="form.center_room_media_2_url"
                  placeholder="Tautan Media"
                ></b-form-input>
              </div>
              <div class="col-md-3 pb-0 mb-0">
                <b-form-group>
                  <b-input-group>
                    <div class="input-group-prepend">
                      <b-button @click="btnUploadCenter2Link" class="btn btn-option" :variant="upload.center2Link">Tautan</b-button>
                    </div>
                    <div class="input-group-append">
                      <b-button @click="btnUploadCenter2Upload" class="btn btn-option" :variant="upload.center2Upload">Unggah</b-button>
                    </div>
                  </b-input-group>
                </b-form-group>
              </div>

               <div class="col-md-12 mt-0 pt-0">
                  <small class="text-muted ">Ukuran: 1280 x 720px</small>
                </div>

              <div
                  class="col-md-12 pb-0 pt-0 mb-0"
                >
                <div class="row">
                  <div class="col-md-4"
                    v-for="(attachmentData2,index) in fileData.center_room_media_2_url"
                    :key="attachmentData2"
                  >
                    <b-card
                      class="m-1 shadow-sm border-primary"
                      body-class="px-4 py-4"
                      v-if="form.center_room_media_2_url_type == 'file'"
                    >
                      
                      <b-card-body class="d-flex justify-content-center p-1" >
                         <div class="row justify-content-end">
                          <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                            <img :src="fileData.center_room_media_2_url[index].file_url" alt="" style="max-width:95%" class="m-2">
                            <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.center_room_media_2_url[index].file_ext === 'pdf' || fileData.center_room_media_2_url[index].file_ext === 'docx' || fileData.center_room_media_2_url[index].file_ext === 'xlxs'"></i>
                            <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.center_room_media_2_url[index].file_ext === 'mp4' || fileData.center_room_media_2_url[index].file_ext === 'm4v'"></i>
                            
                          </div>
                          <div class="col-md-2 col-4 d-inline">
                            <span style="cursor: pointer" @click="deleteAttachCenter2(index)"
                              >X</span
                            >
                          </div>
                        </div>
                      </b-card-body>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <span>{{fileData.center_room_media_2_url[index].file_name}}</span>
                            
                          </div>
                        </div>
                    </b-card>
                  </div>
                </div>

              </div>

            </div>
              <small class="text-danger">{{ error.center_room_media_2_url }}</small>
            </b-form-group>
          </div>
        </div>

        </div>
      </div>
        <!-- end media 2 -->

    <!--start info media -->
      <div class="card card-custom mb-4">
        <div class="card-body bg-gray">
        <div class="row">
           <div class="col-md-3">
              <img src="/img/screenshoot/lobby-booth-kg-media.png" alt="" class="img-prev">
          </div>
          <div class="col-md-9">
            <div class="row px-0 mx-0">
                <div class="col-md-12">
                 <span class="h4">Booth Music Corner OCBC NISP</span>
               </div>
               <div class="col-md-6">
                 <b-form-group>
                   <label for="input-title-info-entertaiment-1">Judul Informasi:</label>
                   <b-form-input
                     id="input-title-info-entertaiment-1"
                     v-model="form.entertaiment_room_media_1_url_title"
                     placeholder="Judul Informasi"
                   ></b-form-input>
                 </b-form-group>
               </div>

               <div class="col-md-6">
                 <b-form-group>
                   <label for="input-subtitle-info-entertaiment-1">Sub-Judul Informasi:</label>
                   <b-form-input
                     id="input-subtitle-info-entertaiment-1"
                     v-model="form.entertaiment_room_media_1_url_subtitle"
                     placeholder="Sub-Judul Informasi"
                   ></b-form-input>
                 </b-form-group>
               </div>

               <div class="col-md-12">
                 <label for="input-article"
                   >Artikel: </label
                 >
                 <ckeditor
                   id="input-article"
                   class="border-ckEditor-muted"
                   v-model="form.entertaiment_room_media_1_url_article"
                   :editor="editor"
                   :config="editorConfig"
                   @ready="onReady"
                 ></ckeditor>

               </div>

               <div class="col-md-12">
                 <label for="input-article"
                   >Gambar: </label
                 >
                   <b-form-file
                     drop-placeholder="Lepaskan File disini..."
                     @change="uploadInfoEntertaiment1"
                   ></b-form-file>
                   <span class="text-teal" v-if="form.entertaiment_room_media_1_url_image_name != ''">Gambar saat ini : {{ form.entertaiment_room_media_1_url_image_name}}</span>
               </div>

               <div class="col-md-12">
                 <b-form-group>
                   <label for="input-site-url-info-entertaiment-1">Tautan Informasi atau Unduhan:</label>
                   <b-form-input
                     id="input-site-url-info-entertaiment-1"
                     v-model="form.entertaiment_room_media_1_url_site_url"
                     placeholder="Tautan Informasi atau Unduhan"
                   ></b-form-input>
                 </b-form-group>
               </div>
             </div>
             <!-- end info media -->

             <!-- start e-catalogue upload media -->
             <!-- <div class="row m-1">

               <div class="col-md-12 py-0">
                   <b-form-group
                     id="input-group-center-room"
                     label="Display E-Catalogue"
                     label-for="input-center-room"

                   >
                   <div class="row">
                     <div class="col-md-12 pb-0 mb-0">
                       <b-form-file
                         multiple
                         drop-placeholder="Lepaskan File disini..."
                         @change="uploadEcatalogueEntertaiment1"
                       ></b-form-file>
                     </div>

                     <div class="col-md-12 mt-0 pt-0">
                       <small class="text-muted ">Ukuran: 720 x 1024px</small>
                     </div>

                     <div
                       class="col-md-9 pb-0 pt-0 mb-0"  
                     >

                     <div class="row">
                       <div class="col-md-4"
                         v-for="(attachmentData,index) in fileData.entertaiment_room_media_1_url_e_catalogue"
                         :key="attachmentData"
                       >
                         <b-card
                           class="m-1 shadow-sm border-primary"
                           body-class="px-4 py-4"
                         >

                           <b-card-body class="d-flex justify-content-center p-1" >
                             <div class="row justify-content-end">
                               <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                 <img :src="fileData.entertaiment_room_media_1_url_e_catalogue[index].file_url" alt="" style="max-width:85%" class="m-2">
                                 <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_1_url_e_catalogue[index].file_ext === 'pdf' || fileData.entertaiment_room_media_1_url_e_catalogue[index].file_ext === 'docx' || fileData.entertaiment_room_media_1_url_e_catalogue[index].file_ext === 'xlxs'"></i>
                               </div>

                               <div class="col-md-2 col-4 d-inline">
                                 <span style="cursor: pointer" @click="deleteEcatalogueAttachEntertaiment1(index)"
                                   >X</span
                                 >
                               </div>
                             </div>
                           </b-card-body>
                             <div class="row">
                               <div class="col-md-12 text-center">
                                 <span>{{fileData.entertaiment_room_media_1_url_e_catalogue[index].file_name}}</span>
                               </div>
                             </div>
                         </b-card>
                       </div>
                     </div>

                     </div>
                   </div>
                   </b-form-group>
               </div>
             </div> -->
             <!-- end e-catalogue upload media -->

             <!-- start entertaiment 1 -->
             <div class="row m-1">
               <div class="col-md-12">   
                   <b-form-group
                     id="input-group-entertaiment-room"
                     label="Display Booth OCBC NISP (Kiri):"
                     label-for="input-entertaiment-room"
                   >
                   <div class="row">
                     <div class="col-md-9 pb-0 mb-0">
                       <b-form-file
                         ref="inputFile2"
                         multiple
                         v-if="uploadType.entertaiment == 'file'"
                         drop-placeholder="Lepaskan File disini..."
                         @change="uploadEntertaiment1"
                       ></b-form-file>

                       <b-form-input
                         v-if="uploadType.entertaiment == 'url'"
                         id="input-entertaiment-room"
                         v-model="form.entertaiment_room_media_1_url"
                         placeholder="Tautan Media"
                       ></b-form-input>
                     </div>
                     <div class="col-md-3 pb-0 mb-0">
                       <b-form-group>
                         <b-input-group>
                           <div class="input-group-prepend">
                             <b-button @click="btnUploadEntertaimentLink" class="btn btn-option" :variant="upload.entertaimentLink">Tautan</b-button>
                           </div>
                           <div class="input-group-append">
                             <b-button @click="btnUploadEntertaimentUpload" class="btn btn-option" :variant="upload.entertaimentUpload">Unggah</b-button>
                           </div>
                         </b-input-group>
                       </b-form-group>
                     </div>

                     <div class="col-md-12 mt-0 pt-0">
                       <small class="text-muted ">Ukuran: 720 x 1024px</small>
                     </div>


                     <div
                       class="col-md-9 pb-0 pt-0 mb-0"

                     >

                     <div class="row">
                       <div class="col-md-4"
                         v-for="(attachmentData,index) in fileData.entertaiment_room_media_1_url"
                         :key="attachmentData"
                       >
                         <b-card
                           class="m-1 shadow-sm border-primary"
                           body-class="px-4 py-4"
                           v-if="form.entertaiment_room_media_1_url_type == 'file'"
                         >

                           <b-card-body class="d-flex justify-content-center p-1" >
                              <div class="row justify-content-end">
                               <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                 <img :src="fileData.entertaiment_room_media_1_url[index].file_url" alt="" style="max-width:95%" class="m-2">
                                 <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_1_url[index].file_ext === 'pdf' || fileData.entertaiment_room_media_1_url[index].file_ext === 'docx' || fileData.entertaiment_room_media_1_url[index].file_ext === 'xlxs'"></i>
                                 <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_1_url[index].file_ext === 'mp4' || fileData.entertaiment_room_media_1_url[index].file_ext === 'm4v'"></i>
                                 
                               </div>
                               <div class="col-md-2 col-4 d-inline">
                                 <span style="cursor: pointer" @click="deleteAttachEntertaiment1(index)"
                                   >X</span
                                 >
                               </div>
                             </div>
                           </b-card-body>
                             <div class="row">
                               <div class="col-md-12 text-center">
                                 <span>{{fileData.entertaiment_room_media_1_url[index].file_name}}</span>
                               </div>
                             </div>
                         </b-card>
                       </div>
                     </div>
                     </div>

                   </div>
                     <small class="text-danger">{{ error.entertaiment_room_media_1_url }}</small>
                   </b-form-group>
               </div>
             </div>
          </div>
        </div>

        </div>
      </div>
        <!-- end entertaiment 1 -->

    <!-- ================================= OCBN NISP BOOTH MUSIC CORNER ======================= -->
      <div class="card card-custom mb-4">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-3">
                <img src="/img/screenshoot/lobby-booth-ocbc.png" alt="" class="img-prev">
            </div>
            <div class="col-md-9">

              <div class="row px-0 mx-0">
               <div class="col-md-12">
                <span class="h4">Booth Music Corner KG MEDIA</span>
                  </div>
                  <div class="col-md-6">
                    <b-form-group>
                      <label for="input-title-info-entertaiment-1">Judul Informasi:</label>
                      <b-form-input
                        id="input-title-info-entertaiment-1"
                        v-model="form.entertaiment_room_media_1_ocbc_url_title"
                        placeholder="Judul Informasi"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group>
                      <label for="input-subtitle-info-entertaiment-1">Sub-Judul Informasi:</label>
                      <b-form-input
                        id="input-subtitle-info-entertaiment-1"
                        v-model="form.entertaiment_room_media_1_ocbc_url_subtitle"
                        placeholder="Sub-Judul Informasi"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <label for="input-article"
                      >Artikel: </label
                    >
                    <ckeditor
                      id="input-article"
                      class="border-ckEditor-muted"
                      v-model="form.entertaiment_room_media_1_ocbc_url_article"
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                    ></ckeditor>

                  </div>

                  <div class="col-md-12">
                    <label for="input-article"
                      >Gambar: </label
                    >
                      <b-form-file
                        drop-placeholder="Lepaskan File disini..."
                        @change="uploadInfoEntertaiment1Ocbc"
                      ></b-form-file>
                      <span class="text-teal" v-if="form.entertaiment_room_media_1_ocbc_url_image_name != ''">Gambar saat ini : {{ form.entertaiment_room_media_1_ocbc_url_image_name}}</span>
                  </div>

                  <div class="col-md-12">
                    <b-form-group>
                      <label for="input-site-url-info-entertaiment-1">Tautan Informasi atau Unduhan:</label>
                      <b-form-input
                        id="input-site-url-info-entertaiment-1"
                        v-model="form.entertaiment_room_media_1_ocbc_url_site_url"
                        placeholder="Tautan Informasi atau Unduhan"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- end info media -->

                <!-- start e-catalogue upload media -->
                <!-- <div class="row m-1">

                  <div class="col-md-12 py-0">
                      <b-form-group
                        id="input-group-center-room"
                        label="Display E-Catalogue"
                        label-for="input-center-room"

                      >
                      <div class="row">
                        <div class="col-md-12 pb-0 mb-0">
                          <b-form-file
                            multiple
                            drop-placeholder="Lepaskan File disini..."
                            @change="uploadEcatalogueEntertaiment1Ocbc"
                          ></b-form-file>
                        </div>

                        <div class="col-md-12 mt-0 pt-0">
                          <small class="text-muted ">Ukuran: 720 x 1024px</small>
                        </div>

                        <div
                          class="col-md-9 pb-0 pt-0 mb-0"  
                        >

                        <div class="row">
                          <div class="col-md-4"
                            v-for="(attachmentData,index) in fileData.entertaiment_room_media_1_ocbc_url_e_catalogue"
                            :key="attachmentData"
                          >
                            <b-card
                              class="m-1 shadow-sm border-primary"
                              body-class="px-4 py-4"
                            >

                              <b-card-body class="d-flex justify-content-center p-1" >
                                <div class="row justify-content-end">
                                  <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                    <img :src="fileData.entertaiment_room_media_1_ocbc_url_e_catalogue[index].file_url" alt="" style="max-width:85%" class="m-2">
                                    <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_1_ocbc_url_e_catalogue[index].file_ext === 'pdf' || fileData.entertaiment_room_media_1_ocbc_url_e_catalogue[index].file_ext === 'docx' || fileData.entertaiment_room_media_1_ocbc_url_e_catalogue[index].file_ext === 'xlxs'"></i>
                                  </div>

                                  <div class="col-md-2 col-4 d-inline">
                                    <span style="cursor: pointer" @click="deleteEcatalogueAttachEntertaiment1Ocbc(index)"
                                      >X</span
                                    >
                                  </div>
                                </div>
                              </b-card-body>
                                <div class="row">
                                  <div class="col-md-12 text-center">
                                    <span>{{fileData.entertaiment_room_media_1_ocbc_url_e_catalogue[index].file_name}}</span>
                                  </div>
                                </div>
                            </b-card>
                          </div>
                        </div>

                        </div>
                      </div>
                      </b-form-group>
                  </div>
                </div> -->
                <!-- end e-catalogue upload media -->

                <!-- start entertaiment 1 -->
                <div class="row m-1">
                  <div class="col-md-12">   
                      <b-form-group
                        id="input-group-entertaiment-room"
                        label="Display Booth KG MEDIA (Kanan):"
                        label-for="input-entertaiment-room"
                      >
                      <div class="row">
                        <div class="col-md-9 pb-0 mb-0">
                          <b-form-file
                            ref="inputFile3"
                            multiple
                            v-if="uploadType.entertaiment == 'file'"
                            drop-placeholder="Lepaskan File disini..."
                            @change="uploadEntertaiment1Ocbc"
                          ></b-form-file>

                          <b-form-input
                            v-if="uploadType.entertaiment == 'url'"
                            id="input-entertaiment-room"
                            v-model="form.entertaiment_room_media_1_ocbc_url"
                            placeholder="Tautan Media"
                          ></b-form-input>
                        </div>
                        <div class="col-md-3 pb-0 mb-0">
                          <b-form-group>
                            <b-input-group>
                              <div class="input-group-prepend">
                                <b-button @click="btnUploadEntertaimentOcbcLink" class="btn btn-option" :variant="upload.entertaimentOcbcLink">Tautan</b-button>
                              </div>
                              <div class="input-group-append">
                                <b-button @click="btnUploadEntertaimentOcbcUpload" class="btn btn-option" :variant="upload.entertaimentOcbcUpload">Unggah</b-button>
                              </div>
                            </b-input-group>
                          </b-form-group>
                        </div>

                        <div class="col-md-12 mt-0 pt-0">
                          <small class="text-muted ">Ukuran: 720 x 1024px</small>
                        </div>


                        <div
                          class="col-md-12 pb-0 pt-0 mb-0"

                        >

                        <div class="row">
                          <div class="col-md-4"
                            v-for="(attachmentData,index) in fileData.entertaiment_room_media_1_ocbc_url"
                            :key="attachmentData"
                          >
                            <b-card
                              class="m-1 shadow-sm border-primary"
                              body-class="px-4 py-4"
                              v-if="form.entertaiment_room_media_1_ocbc_url_type == 'file'"
                            >

                              <b-card-body class="d-flex justify-content-center p-1" >
                                 <div class="row justify-content-end">
                                  <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                    <img :src="fileData.entertaiment_room_media_1_ocbc_url[index].file_url" alt="" style="max-width:95%" class="m-2">
                                    <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_1_ocbc_url[index].file_ext === 'pdf' || fileData.entertaiment_room_media_1_ocbc_url[index].file_ext === 'docx' || fileData.entertaiment_room_media_1_ocbc_url[index].file_ext === 'xlxs'"></i>
                                    <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_1_ocbc_url[index].file_ext === 'mp4' || fileData.entertaiment_room_media_1_ocbc_url[index].file_ext === 'm4v'"></i>
                                  </div>
                                  <div class="col-md-2 col-4 d-inline">
                                    <span style="cursor: pointer" @click="deleteAttachEntertaiment1Ocbc(index)"
                                      >X</span
                                    >
                                  </div>
                                </div>
                              </b-card-body>
                                <div class="row">
                                  <div class="col-md-12 text-center">
                                    <span>{{fileData.entertaiment_room_media_1_ocbc_url[index].file_name}}</span>
                                  </div>
                                </div>
                            </b-card>
                          </div>
                        </div>
                        </div>

                      </div>
                        <small class="text-danger">{{ error.entertaiment_room_media_1_ocbc_url }}</small>
                      </b-form-group>
                  </div>
                </div>
                </div>
              </div>
        
        </div>
      </div>
      <!-- ================================= END OCBN NISP BOOTH MUSIC CORNER ======================= -->

      <!--start info media -->
      <div class="card card-custom mb-4">
        <div class="card-body bg-gray">
          <div class="row">
            <div class="col-md-3">
              <img src="/img/screenshoot/music-corner-kg-media.png" alt="" class="img-prev">
            </div>
            <div class="col-md-9">
              <div class="row px-0 mx-0">
                 <div class="col-md-12">
                  <span class="h4">Ruang Hangout Corner KG Media</span>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="input-title-info-entertaiment-2">Judul Informasi:</label>
                    <b-form-input
                      id="input-title-info-entertaiment-2"
                      v-model="form.entertaiment_room_media_2_url_title"
                      placeholder="Judul Informasi"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <label for="input-subtitle-info-entertaiment-2">Sub-Judul Informasi:</label>
                    <b-form-input
                      id="input-subtitle-info-entertaiment-2"
                      v-model="form.entertaiment_room_media_2_url_subtitle"
                      placeholder="Sub-Judul Informasi"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <label for="input-article"
                    >Artikel: </label
                  >
                  <ckeditor
                    id="input-article"
                    class="border-ckEditor-muted"
                    v-model="form.entertaiment_room_media_2_url_article"
                    :editor="editor"
                    :config="editorConfig"
                    @ready="onReady"
                  ></ckeditor>
                </div>

                <div class="col-md-12">
                  <label for="input-article"
                    >Gambar: </label
                  >
                    <b-form-file
                      drop-placeholder="Lepaskan File disini..."
                      @change="uploadInfoEntertaiment2"
                    ></b-form-file>
                    <span class="text-teal" v-if="form.entertaiment_room_media_2_url_image_name != ''">Gambar saat ini : {{ form.entertaiment_room_media_2_url_image_name}}</span>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label for="input-site-url-info-entertaiment-2">Tautan Informasi atau Unduhan:</label>
                    <b-form-input
                      id="input-site-url-info-entertaiment-2"
                      v-model="form.entertaiment_room_media_2_url_site_url"
                      placeholder="Tautan Informasi atau Unduhan"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <!-- end info media -->

              <!-- start e-catalogue upload media -->
              <div class="row m-1">
                <div class="col-md-12 py-0">
                    <b-form-group
                      id="input-group-center-room"
                      label="Display E-Catalogue Ruang Hangout Corner:"
                      label-for="input-center-room"

                    >
                    <div class="row">
                      <div class="col-md-12 pb-0 mb-0">
                        <b-form-file
                          ref="inputFile4"
                          multiple
                          drop-placeholder="Lepaskan File disini..."
                          @change="uploadEcatalogueEntertaiment2"
                        ></b-form-file>
                      </div>

                      <div class="col-md-12 mt-0 pt-0">
                        <small class="text-muted ">Ukuran: 1280 x 720px</small>
                      </div>

                      <div
                        class="col-md-9 pb-0 pt-0 mb-0"  
                      >

                      <div class="row">
                        <div class="col-md-4"
                          v-for="(attachmentData,index) in fileData.entertaiment_room_media_2_url_e_catalogue"
                          :key="attachmentData"
                        >
                          <b-card
                            class="m-1 shadow-sm border-primary"
                            body-class="px-4 py-4"
                          >

                            <b-card-body class="d-flex justify-content-center p-1" >
                              <div class="row justify-content-end">
                                <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                  <img :src="fileData.entertaiment_room_media_2_url_e_catalogue[index].file_url" alt="" style="max-width:85%" class="m-2">
                                  <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_2_url_e_catalogue[index].file_ext === 'pdf' || fileData.entertaiment_room_media_2_url_e_catalogue[index].file_ext === 'docx' || fileData.entertaiment_room_media_2_url_e_catalogue[index].file_ext === 'xlxs'"></i>
                                  <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_2_url_e_catalogue[index].file_ext === 'mp4' || fileData.entertaiment_room_media_2_url_e_catalogue[index].file_ext === 'm4v'"></i>
                                </div>

                                <div class="col-md-2 col-4 d-inline">
                                  <span style="cursor: pointer" @click="deleteEcatalogueAttachEntertaiment2(index)"
                                    >X</span
                                  >
                                </div>
                              </div>
                            </b-card-body>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <span>{{fileData.entertaiment_room_media_2_url_e_catalogue[index].file_name}}</span>
                                </div>
                              </div>
                          </b-card>
                        </div>
                      </div>

                      </div>
                    </div>
                    </b-form-group>
                </div>
              </div>
              <!-- end e-catalogue upload media -->

              <!-- start entertaiment 2 -->
              <div class="row m-1">
                
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-entertaiment-room"
                    label="Display Video Ruang Hangout Corner:"
                    label-for="input-entertaiment-room"
                  >
                  <div class="row">
                    <div class="col-md-9 pb-0 mb-0">
                      <b-form-file
                        ref="inputFile5"
                        multiple
                        v-if="uploadType.entertaiment2 == 'file'"
                        drop-placeholder="Lepaskan File disini..."
                        @change="uploadEntertaiment2"
                      ></b-form-file>

                      <b-form-input
                        v-if="uploadType.entertaiment2 == 'url'"
                        id="input-entertaiment-room"
                        v-model="form.entertaiment_room_media_2_url"
                        placeholder="Tautan Media"
                      ></b-form-input>
                    </div>
                    <div class="col-md-3 pb-0 mb-0">
                      <b-form-group>
                        <b-input-group>
                          <div class="input-group-prepend">
                            <b-button @click="btnUploadEntertaiment2Link" class="btn btn-option" :variant="upload.entertaiment2Link">Tautan</b-button>
                          </div>
                          <div class="input-group-append">
                            <b-button @click="btnUploadEntertaiment2Upload" class="btn btn-option" :variant="upload.entertaiment2Upload">Unggah</b-button>
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </div>

                      <div class="col-md-12 mt-0 pt-0">
                        <small class="text-muted ">Ukuran Video: 1280 x 720px</small>
                      </div>

                    <div
                        class="col-md-9 pb-0 pt-0 mb-0"

                      >

                      <div class="row">
                        <div class="col-md-4"
                          v-for="(attachmentData,index) in fileData.entertaiment_room_media_2_url"
                          :key="attachmentData"
                        >
                          <b-card
                            class="m-1 shadow-sm border-primary"
                            body-class="px-4 py-4"
                            v-if="form.entertaiment_room_media_2_url_type == 'file'"
                          >

                            <b-card-body class="d-flex justify-content-center p-1" >
                               <div class="row justify-content-end">
                                <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                  <img :src="fileData.entertaiment_room_media_2_url[index].file_url" alt="" style="max-width:95%" class="m-2">
                                  <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_2_url[index].file_ext === 'pdf' || fileData.entertaiment_room_media_2_url[index].file_ext === 'docx' || fileData.entertaiment_room_media_2_url[index].file_ext === 'xlxs'"></i>
                                  <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.entertaiment_room_media_2_url[index].file_ext === 'mp4' || fileData.entertaiment_room_media_2_url[index].file_ext === 'm4v'"></i>
                                </div>
                                <div class="col-md-2 col-4 d-inline">
                                  <span style="cursor: pointer" @click="deleteAttachEntertaiment2(index)"
                                    >X</span
                                  >
                                </div>
                              </div>
                            </b-card-body>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <span>{{fileData.entertaiment_room_media_2_url[index].file_name}}</span>
                                </div>
                              </div>
                          </b-card>
                        </div>
                      </div>
                      </div>

                  </div>
                    <small class="text-danger">{{ error.entertaiment_room_media_2_url }}</small>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <!-- end entertaiment 2 -->

      <!--start info media -->
      <div class="card card-custom mb-4">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-3">
              <img src="/img/screenshoot/music-corner-ocbc.png" alt="" class="img-prev">
            </div>
            <div class="col-md-9">
              <div class="row px-0 mx-0">
                 <div class="col-md-12">
                  <span class="h4">Ruang Hangout Corner OCBC NISP</span>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="input-title-info-hangout">Judul Informasi:</label>
                    <b-form-input
                      id="input-title-info-hangout"
                      v-model="form.hangout_room_mini_booth_video_title"
                      placeholder="Judul Informasi"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <label for="input-subtitle-info-hangout">Sub-Judul Informasi:</label>
                    <b-form-input
                      id="input-subtitle-info-hangout"
                      v-model="form.hangout_room_mini_booth_video_subtitle"
                      placeholder="Sub-Judul Informasi"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <label for="input-article"
                    >Artikel: </label
                  >
                  <ckeditor
                    id="input-article"
                    class="border-ckEditor-muted"
                    v-model="form.hangout_room_mini_booth_video_article"
                    :editor="editor"
                    :config="editorConfig"
                    @ready="onReady"
                  ></ckeditor>
                </div>

                <div class="col-md-12">
                  <label for="input-article"
                    >Gambar: </label
                  >
                    <b-form-file
                      drop-placeholder="Lepaskan File disini..."
                      @change="uploadInfoHangout1"
                    ></b-form-file>
                    <span class="text-teal" v-if="form.hangout_room_mini_booth_video_image_name != ''">Gambar saat ini : {{ form.hangout_room_mini_booth_video_image_name}}</span>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label for="input-site-url-info-entertaiment-2">Tautan Informasi atau Unduhan:</label>
                    <b-form-input
                      id="input-site-url-info-entertaiment-2"
                      v-model="form.hangout_room_mini_booth_video_site_url"
                      placeholder="Tautan Informasi atau Unduhan"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <!-- end info media -->

              <!-- start e-catalogue upload media -->
              <div class="row m-1">
                <div class="col-md-12 py-0">
                    <b-form-group
                      id="input-group-center-room"
                      label="Display E-Catalogue Ruang Hangout Corner:"
                      label-for="input-center-room"

                    >
                    <div class="row">
                      <div class="col-md-12 pb-0 mb-0">
                        <b-form-file
                          ref="inputFile6"
                          multiple
                          drop-placeholder="Lepaskan File disini..."
                          @change="uploadEcatalogueMiniBoothVideo"
                        ></b-form-file>
                      </div>

                      <div class="col-md-12 mt-0 pt-0">
                        <small class="text-muted ">Ukuran: 1280 x 720px</small>
                      </div>

                      <div
                        class="col-md-9 pb-0 pt-0 mb-0"  
                      >

                      <div class="row">
                        <div class="col-md-4"
                          v-for="(attachmentData,index) in fileData.hangout_room_mini_booth_video_e_catalogue"
                          :key="attachmentData"
                        >
                          <b-card
                            class="m-1 shadow-sm border-primary"
                            body-class="px-4 py-4"
                          >

                            <b-card-body class="d-flex justify-content-center p-1" >
                              <div class="row justify-content-end">
                                <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                  <img :src="fileData.hangout_room_mini_booth_video_e_catalogue[index].file_url" alt="" style="max-width:85%" class="m-2">
                                  <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.hangout_room_mini_booth_video_e_catalogue[index].file_ext === 'pdf' || fileData.hangout_room_mini_booth_video_e_catalogue[index].file_ext === 'docx' || fileData.hangout_room_mini_booth_video_e_catalogue[index].file_ext === 'xlxs'"></i>
                                  <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.hangout_room_mini_booth_video_e_catalogue[index].file_ext === 'mp4' || fileData.hangout_room_mini_booth_video_e_catalogue[index].file_ext === 'm4v'"></i>
                                  
                                </div>

                                <div class="col-md-2 col-4 d-inline">
                                  <span style="cursor: pointer" @click="deleteEcatalogueAttachHo(index)"
                                    >X</span
                                  >
                                </div>
                              </div>
                            </b-card-body>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <span>{{fileData.hangout_room_mini_booth_video_e_catalogue[index].file_name}}</span>
                                </div>
                              </div>
                          </b-card>
                        </div>
                      </div>

                      </div>
                    </div>
                    </b-form-group>
                </div>
              </div>
              <!-- end e-catalogue upload media -->

              <!-- start hangout video -->
              <div class="row m-1">
                
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-mini-booth-room"
                    label="Display Video Ruang Hangout Corner :"
                    label-for="input-mini-booth-room"
                  >
                  <div class="row p-0">
                    <div class="col-md-9 pb-0 mb-0">
                      <b-form-file
                        ref="inputFile7"
                        multiple
                        v-if="uploadType.hangoutVideo == 'file'"
                        drop-placeholder="Lepaskan File disini..."
                        @change="uploadMiniBoothVideo"
                      ></b-form-file>

                      <b-form-input
                        v-if="uploadType.hangoutVideo == 'url'"
                        id="input-mini-booth-room"
                        v-model="form.hangout_room_mini_booth_video"
                        placeholder="Tautan Video"
                      ></b-form-input>
                    </div>
                    <div class="col-md-3 pb-0 mb-0">
                      <b-form-group>
                        <b-input-group>
                          <div class="input-group-prepend">
                            <b-button @click="btnUploadhangoutLink" class="btn btn-option" :variant="upload.hangoutVideoLink">Tautan</b-button>
                          </div>
                          <div class="input-group-append">
                            <b-button @click="btnUploadhangoutUpload" class="btn btn-option" :variant="upload.hangoutVideoUpload">Unggah</b-button>
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="col-md-12 mt-0 pt-0">
                        <small class="text-muted ">Ukuran Video: 1280 x 720px</small>
                      </div>

                    <div
                        class="col-md-9 pb-0 pt-0 mb-0"

                      >

                      <div class="row">
                        <div class="col-md-4"
                          v-for="(attachmentData,index) in fileData.hangout_room_mini_booth_video"
                          :key="attachmentData"
                        >
                          <b-card
                            class="m-1 shadow-sm border-primary"
                            body-class="px-4 py-4"
                            v-if="form.hangout_room_mini_booth_video_type == 'file'"
                          >

                            <b-card-body class="d-flex justify-content-center p-1" >
                               <div class="row justify-content-end">
                                <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                  <img :src="fileData.hangout_room_mini_booth_video[index].file_url" alt="" style="max-width:95%" class="m-2">
                                  <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.hangout_room_mini_booth_video[index].file_ext === 'pdf' || fileData.hangout_room_mini_booth_video[index].file_ext === 'docx' || fileData.hangout_room_mini_booth_video[index].file_ext === 'xlxs'"></i>
                                  <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.hangout_room_mini_booth_video[index].file_ext === 'mp4' || fileData.hangout_room_mini_booth_video[index].file_ext === 'm4v'"></i>
                                  <i class="fas fa-file-audio fa-5x text-primary m-2" v-if="fileData.hangout_room_mini_booth_video[index].file_ext === 'mp3' "></i>
                                </div>
                                <div class="col-md-2 col-4 d-inline">
                                  <span style="cursor: pointer" @click="deleteAttachHo(index)"
                                    >X</span
                                  >
                                </div>
                              </div>
                            </b-card-body>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <span>{{fileData.hangout_room_mini_booth_video[index].file_name}}</span>
                                </div>
                              </div>
                          </b-card>
                        </div>
                      </div>
                      </div>

                  </div>
                    <small class="text-danger">{{ error.hangout_room_mini_booth_video }}</small>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end hangout video -->

      <!--start Music -->
      <div class="card card-custom mb-4">
        <div class="card-body bg-gray">
          <div class="row">
            <div class="col-md-3">
              <img src="/img/screenshoot/music-corner.png" alt="" class="img-prev">
            </div>
            <div class="col-md-9">
              <!-- start hangout video -->
              <div class="row m-1">
                <div class="col-md-12">
                  <span class="h4">Music Corner :</span>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-mini-booth-room"
                    label="File Music (mp3) :"
                    label-for="input-mini-booth-room"
                  >
                  <div class="row p-0">
                    <div class="col-md-12 pb-0 mb-0">
                      <b-form-file
                        ref="inputFile8"
                        multiple
                        drop-placeholder="Lepaskan File disini..."
                        @change="uploadMusic"
                      ></b-form-file>

                    </div>
                    <div
                      class="col-md-9 pb-0 pt-0 mb-0"
                    >

                      <div class="row">
                        <div class="col-md-4"
                          v-for="(attachmentData,index) in fileData.entertaiment_room_music_api"
                          :key="attachmentData"
                        >
                          <b-card
                            class="m-1 shadow-sm border-primary"
                            body-class="px-4 py-4"
                          >

                            <b-card-body class="d-flex justify-content-center p-1" >
                               <div class="row justify-content-end">
                                <div class="col-md-8 col-8 m-1 p-1 d-flex justify-content-center">
                                  <img :src="fileData.entertaiment_room_music_api[index].file_url" alt="" style="max-width:95%" class="m-2">
                                  <i class="fas fa-file fa-5x text-primary m-2" v-if="fileData.entertaiment_room_music_api[index].file_ext === 'pdf' || fileData.entertaiment_room_music_api[index].file_ext === 'docx' || fileData.entertaiment_room_music_api[index].file_ext === 'xlxs'"></i>
                                  <i class="fas fa-file-video fa-5x text-primary m-2" v-if="fileData.entertaiment_room_music_api[index].file_ext === 'mp4' || fileData.entertaiment_room_music_api[index].file_ext === 'm4v'"></i>
                                  <i class="fas fa-file-audio fa-5x text-primary m-2" v-if="fileData.entertaiment_room_music_api[index].file_ext === 'mp3' "></i>
                                </div>
                                <div class="col-md-2 col-4 d-inline">
                                  <span style="cursor: pointer" @click="deleteMusic(index)"
                                    >X</span
                                  >
                                </div>
                              </div>
                            </b-card-body>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <span>{{fileData.entertaiment_room_music_api[index].file_name}}</span>
                                </div>
                              </div>
                          </b-card>
                        </div>
                      </div>
                      </div>

                  </div>
                    <small class="text-danger">{{ error.entertaiment_room_music_api }}</small>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end music -->

      
      <div class="card card-custom mb-4">
        <div class="card-body ">
        <!-- <b-form-group
          id="input-group-mini-booth-room"
          label="Mini Booth PDF Brosur:"
          label-for="input-mini-booth-room"
        >
            <b-form-file
              drop-placeholder="Lepaskan File disini..."
              @change="uploadMiniBoothBrochure"
            ></b-form-file>
          <small class="text-danger">{{ error.hangout_room_mini_booth_pdf_brochure }}</small>
        </b-form-group> -->
        
        <!-- <div class="row mb-2">
          <div
            class="col-md-9 pb-0 pt-0 mb-0"
          >
            <b-card
              class="m-1 shadow-sm border-primary"
              body-class="px-4 py-4"

            >
              <b-card-text>
                <span>{{ form.hangout_room_mini_booth_pdf_brochure_name}}</span>
              </b-card-text>
            </b-card>
          </div>
        </div> -->

         <b-form-group
          id="input-group-microsite"
          label="Front Office Streaming:"
          label-for="input-microsite"
        >
          <b-form-input
            id="input-phone-microsite"
            v-model="form.hangout_room_mini_booth_pdf_microsite_url"
            placeholder="Tautan Live Youtube"
          ></b-form-input>
          <small class="text-danger">{{ error.hangout_room_mini_booth_pdf_microsite_url }}</small>
        </b-form-group>
        </div>
      </div>

      </div>

      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="card card-custom mb-4">
        <div class="card-body ">
          <div class="d-flex justify-content-end pt-4">
            <div>
              <b-button type="submit" variant="primary">Simpan Perubahan</b-button>
            </div>
          </div>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    attachments: Array,
    fileData: Object,
    url_image:Object,
  },
  data() {
    return {
      // errors
      error: {
        center_room_media_1_url :"",
        center_room_media_2_url:"",
        entertaiment_room_media_1_url:"",
        entertaiment_room_media_2_url:"",
        entertaiment_room_music_api:"",
        hangout_room_mini_booth_video:"",
        hangout_room_mini_booth_pdf_brochure:"",
        hangout_room_mini_booth_pdf_microsite_url:"",
      },

      // btn input type attr
      upload:{
        // link
        centerLink : "primary",
        center2Link : "primary",
        entertaimentLink : "primary",
        entertaimentOcbcLink : "primary",
        entertaiment2Link : "primary",
        hangoutVideoLink : "primary",
        
        // upload
        centerUpload : "outline-primary",
        center2Upload : "outline-primary",
        entertaimentUpload : "outline-primary",
        entertaimentOcbcUpload : "outline-primary",
        entertaiment2Upload : "outline-primary",
        hangoutVideoUpload : "outline-primary",
      },
    
      uploadType:{
        center1:"url",
        center2:"url",
        entertaiment:"url",
        entertaimentOcbc:"file",
        entertaiment2:"url",
        hangoutVideo:"url",
      },

      // options
      formData : new FormData(),
      roles:[],

      // delete file
      deletedIndexAttachmentCenter1:[],

      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          // "|",
          // "fontFamily",
          // "fontSize",
          // "fontColor",
          // "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          // "indent",
          // "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          // "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        alignment: {
          options: ["left", "right", "center", "justify"],
        },
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      
    };
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    // media center 1
    btnUploadCenter1Link(){
      this.upload.centerLink = "primary"
      this.upload.centerUpload = "outline-primary"
      this.uploadType.center1 = "url"
      this.form.center_room_media_1_url_type = "url"
    },
    btnUploadCenter1Upload(){
      this.upload.centerUpload = "primary"
      this.upload.centerLink = "outline-primary"
      this.uploadType.center1 = "file"
      this.form.center_room_media_1_url_type = "file"
    },

    // media center 2
    btnUploadCenter2Link(){
      this.upload.center2Link = "primary"
      this.upload.center2Upload = "outline-primary"
      this.uploadType.center2 = "url"
      this.form.center_room_media_2_url_type = "url"
    },
    btnUploadCenter2Upload(){
      this.upload.center2Upload = "primary"
      this.upload.center2Link = "outline-primary"
      this.uploadType.center2 = "file"
      this.form.center_room_media_2_url_type = "file"
    },
    
    // entertaiment 1
    btnUploadEntertaimentLink(){
      this.upload.entertaimentLink = "primary"
      this.upload.entertaimentUpload = "outline-primary"
      this.uploadType.entertaiment = "url",
      this.form.entertaiment_room_media_1_url_type = "url"
    },
    btnUploadEntertaimentUpload(){
      this.upload.entertaimentUpload = "primary"
      this.upload.entertaimentLink = "outline-primary"
      this.uploadType.entertaiment = "file"
      this.form.entertaiment_room_media_1_url_type = "file"
    },
    
    // entertaiment 1 Ocbc
    btnUploadEntertaimentLinkOcbc(){
      this.upload.entertaimentLink = "primary"
      this.upload.entertaimentUpload = "outline-primary"
      this.uploadType.entertaiment = "url",
      this.form.entertaiment_room_media_1_url_type = "url"
    },
    btnUploadEntertaimentUploadOcbc(){
      this.upload.entertaimentUpload = "primary"
      this.upload.entertaimentLink = "outline-primary"
      this.uploadType.entertaiment = "file"
      this.form.entertaiment_room_media_1_url_type = "file"
    },
    
    // entertaiment 2
    btnUploadEntertaiment2Link(){
      this.upload.entertaiment2Link = "primary"
      this.upload.entertaiment2Upload = "outline-primary"
      this.uploadType.entertaiment2 = "url"
      this.form.entertaiment_room_media_2_url_type = "url"
    },
    btnUploadEntertaiment2Upload(){
      this.upload.entertaiment2Upload = "primary"
      this.upload.entertaiment2Link = "outline-primary"
      this.uploadType.entertaiment2 = "file"
      this.form.entertaiment_room_media_2_url_type = "file"
    },

    // hangout mini booth video
    btnUploadhangoutLink(){
      this.upload.hangoutVideoLink = "primary"
      this.upload.hangoutVideoUpload = "outline-primary"
      this.uploadType.hangoutVideo = "url"
      this.form.hangout_room_mini_booth_video_type = "url"
    },
    btnUploadhangoutUpload(){
      this.upload.hangoutVideoUpload = "primary"
      this.upload.hangoutVideoLink = "outline-primary"
      this.uploadType.hangoutVideo = "file"
      this.form.hangout_room_mini_booth_video_type = "file"
    },


    // file upload
    uploadRegister(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("register_image[]", event.target.files[a]);
      }
    },

    uploadMenuAdsMedia(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("menu_ads_media[]", event.target.files[a]);
      }
    },

    uploadCenter1(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("center_room_media_1_url[]", event.target.files[a]);
      }
    },

    uploadCenter2(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("center_room_media_2_url[]", event.target.files[a]);
      }
    },
    
    uploadEntertaiment1(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_1_url[]", event.target.files[a]);
      }
    },

    uploadEntertaiment1Ocbc(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_1_ocbc_url[]", event.target.files[a]);
      }
    },
    
    uploadEntertaiment2(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_2_url[]", event.target.files[a]);
      }        
      
    },

    uploadMiniBoothVideo(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("hangout_room_mini_booth_video[]", event.target.files[a]);
      }
    },
    
    uploadMusic(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_music_api[]", event.target.files[a]);
      }
    },

    uploadMiniBoothBrochure(event) {
      this.formData.append("hangout_room_mini_booth_pdf_brochure", event.target.files[0]);
    },

    // file e catalogue upload
    uploadEcatalogueCenter1(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("center_room_media_1_url_e_catalogue[]", event.target.files[a]);
      }
    },

    uploadEcatalogueCenter2(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("center_room_media_2_url_e_catalogue[]", event.target.files[a]);
      }
    },
    
    uploadEcatalogueEntertaiment1(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_1_url_e_catalogue[]", event.target.files[a]);
      }
    },

    uploadEcatalogueEntertaiment1Ocbc(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_1_ocbc_url_e_catalogue[]", event.target.files[a]);
      }
    },
    
    uploadEcatalogueEntertaiment2(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_2_url_e_catalogue[]", event.target.files[a]);
      }        
      
    },

    uploadEcatalogueMiniBoothVideo(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("hangout_room_mini_booth_video_e_catalogue[]", event.target.files[a]);
      }
    },

    // upload information media
    uploadInfoCenter1(event) {
      this.formData.append("center_room_media_1_url_image", event.target.files[0]);
    },
    
    uploadInfoCenter2(event) {
      this.formData.append("center_room_media_2_url_image", event.target.files[0]);
    },

    uploadInfoEntertaiment1(event) {
      this.formData.append("entertaiment_room_media_1_url_image", event.target.files[0]);
    },

    uploadInfoEntertaiment1Ocbc(event) {
      this.formData.append("entertaiment_room_media_1_ocbc_url_image", event.target.files[0]);
    },
    
    uploadInfoEntertaiment2(event) {
      this.formData.append("entertaiment_room_media_2_url_image", event.target.files[0]);
    },

    uploadInfoHangout1(event) {
      this.formData.append("hangout_room_mini_booth_video_image", event.target.files[0]);
    },

    // on edited display data
    uploadCenterEdited1(data){
      this.uploadType.center1 = data.center_room_media_1_url_type
      
      if(this.uploadType.center1 == "file"){
        this.upload.centerUpload = "primary"
        this.upload.centerLink = "outline-primary"
      }else{
        this.upload.centerLink = "primary"
        this.upload.centerUpload = "outline-primary"
      }
    },

    uploadCenterEdited2(data){
      this.uploadType.center2 = data.center_room_media_2_url_type
      
      if(this.uploadType.center2 == "file"){
        this.upload.center2Upload = "primary"
        this.upload.center2Link = "outline-primary"
      }else{
        this.upload.center2Link = "primary"
        this.upload.center2Upload = "outline-primary"
      }
    },
      
    uploadEntertaimentEdited1(data){
      this.uploadType.entertaiment = data.entertaiment_room_media_1_url_type
      
      if(this.uploadType.entertaiment == "file"){
        this.upload.entertaimentUpload = "primary"
        this.upload.entertaimentLink = "outline-primary"
      }else{
        this.upload.entertaimentLink = "primary"
        this.upload.entertaimentUpload = "outline-primary"
      }
    },

    uploadEntertaimentEdited1Ocbc(data){
      this.uploadType.entertaiment = data.entertaiment_room_media_1_url_type
      
      if(this.uploadType.entertaiment == "file"){
        this.upload.entertaimentUpload = "primary"
        this.upload.entertaimentLink = "outline-primary"
      }else{
        this.upload.entertaimentLink = "primary"
        this.upload.entertaimentUpload = "outline-primary"
      }
    },

    uploadEntertaimentEdited2(data){
      this.uploadType.entertaiment2 = data.entertaiment_room_media_2_url_type
      
      if(this.uploadType.entertaiment2 == "file"){
        this.upload.entertaiment2Upload = "primary"
        this.upload.entertaiment2Link = "outline-primary"
      }else{
        this.upload.entertaiment2Link = "primary"
        this.upload.entertaiment2Upload = "outline-primary"
      }
    },
    uploadMiniBoothEdited(data){
      this.uploadType.hangoutVideo = data.hangout_room_mini_booth_video_type
      
      if(this.uploadType.entertaiment2 == "file"){
        this.upload.hangoutVideoUpload = "primary"
        this.upload.hangoutVideoLink = "outline-primary"
      }else{
        this.upload.hangoutVideoLink = "primary"
        this.upload.hangoutVideoUpload = "outline-primary"
      }
    },
    
    validationMessage() {
      Swal.fire({
        title: "Gagal",
        text: "Harap periksa kembali form",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async statusOptions() {
      let response = await module.setTreeSelect("api/member-roles");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Pilih Peran",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      //  let attachments = this.attachments.join();
        
        if(this.attachments.center_room_media_1_url == undefined){
          this.attachments.center_room_media_1_url = [""]
        }

        if(this.attachments.center_room_media_2_url == undefined){
          this.attachments.center_room_media_2_url = [""]
        }

        if(this.attachments.entertaiment_room_media_1_url == undefined){
          this.attachments.entertaiment_room_media_1_url = [""]
        }

        if(this.attachments.entertaiment_room_media_1_ocbc_url == undefined){
          this.attachments.entertaiment_room_media_1_ocbc_url = [""]
        }

        if(this.attachments.entertaiment_room_media_2_url == undefined){
          this.attachments.entertaiment_room_media_2_url = [""]
        }
        
        if(this.attachments.hangout_room_mini_booth_video == undefined){
          this.attachments.hangout_room_mini_booth_video = [""]
        }
        
        if(this.attachments.entertaiment_room_music_api == undefined){
          this.attachments.entertaiment_room_music_api = [""]
        }

        // e catalogue
        if(this.attachments.center_room_media_1_url_e_catalogue == undefined){
          this.attachments.center_room_media_1_url_e_catalogue = [""]
        }

        if(this.attachments.center_room_media_2_url_e_catalogue == undefined){
          this.attachments.center_room_media_2_url_e_catalogue = [""]
        }

        if(this.attachments.entertaiment_room_media_1_url_e_catalogue == undefined){
          this.attachments.entertaiment_room_media_1_url_e_catalogue = [""]
        }
        
        if(this.attachments.entertaiment_room_media_1_ocbc_url_e_catalogue == undefined){
          this.attachments.entertaiment_room_media_1_ocbc_url_e_catalogue = [""]
        }

        if(this.attachments.entertaiment_room_media_2_url_e_catalogue == undefined){
          this.attachments.entertaiment_room_media_2_url_e_catalogue = [""]
        }
        
        if(this.attachments.hangout_room_mini_booth_video_e_catalogue == undefined){
          this.attachments.hangout_room_mini_booth_video_e_catalogue = [""]
        }
        
        if(this.attachments.register_image == undefined){
          this.attachments.register_image = [""]
        }
        
        if(this.attachments.menu_ads_media == undefined){
          this.attachments.menu_ads_media = [""]
        }
        
        // media
        this.formData.append("center_room_media_1_url_list", this.attachments.center_room_media_1_url);
        this.formData.append("center_room_media_2_url_list", this.attachments.center_room_media_2_url);
        this.formData.append("entertaiment_room_media_1_url_list", this.attachments.entertaiment_room_media_1_url);
        this.formData.append("entertaiment_room_media_1_ocbc_url_list", this.attachments.entertaiment_room_media_1_ocbc_url);
        this.formData.append("entertaiment_room_media_2_url_list", this.attachments.entertaiment_room_media_2_url);
        this.formData.append("hangout_room_mini_booth_video_list", this.attachments.hangout_room_mini_booth_video);
        this.formData.append("entertaiment_room_music_api_list", this.attachments.entertaiment_room_music_api);
        this.formData.append("register_image_list", this.attachments.register_image);
        this.formData.append("menu_ads_media_list", this.attachments.menu_ads_media);
        
        // e catalogue
        this.formData.append("center_room_media_1_url_e_catalogue_list", this.attachments.center_room_media_1_url_e_catalogue);
        this.formData.append("center_room_media_2_url_e_catalogue_list", this.attachments.center_room_media_2_url_e_catalogue);
        this.formData.append("entertaiment_room_media_1_url_e_catalogue_list", this.attachments.entertaiment_room_media_1_url_e_catalogue);
        this.formData.append("entertaiment_room_media_1_ocbc_url_e_catalogue_list", this.attachments.entertaiment_room_media_1_ocbc_url_e_catalogue);
        this.formData.append("entertaiment_room_media_2_url_e_catalogue_list", this.attachments.entertaiment_room_media_2_url_e_catalogue);
        this.formData.append("hangout_room_mini_booth_video_e_catalogue_list", this.attachments.hangout_room_mini_booth_video_e_catalogue);

        this.formData.append("deletedIndexId", JSON.stringify(this.deletedIndexAttachment));
        for (const [key, value] of Object.entries(this.form)) {
          if (key !== "center_room_media_1_url_list" || 
              key !== "center_room_media_2_url_list" || 
              key !== "entertaiment_room_media_1_url_list" || 
              key !== "entertaiment_room_media_1_ocbc_url_list" || 
              key !== "entertaiment_room_media_2_url_list" || 
              key !== "hangout_room_mini_booth_video_list" ||
              key !== "entertaiment_room_music_api_list" ||
              key !== "center_room_media_1_url_e_catalogue_list" || 
              key !== "center_room_media_2_url_e_catalogue_list" || 
              key !== "entertaiment_room_media_1_url_e_catalogue_list" || 
              key !== "entertaiment_room_media_1_ocbc_url_e_catalogue_list" || 
              key !== "entertaiment_room_media_2_url_e_catalogue_list" || 
              key !== "hangout_room_mini_booth_video_e_catalogue_list" ||
              key !== "register_image_list" ||
              key !== "menu_ads_media_list" 
              ) 
          {
            this.formData.append(key, value);
          }
        }
      // for (const [key, value] of Object.entries(this.form)) {
      //     this.formData.append(key, value);
      // }

      // Make Request
      // this.form.photo_url = this.photo_url
      // this.form.company_logo_url = this.company_logo_url

      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true, 
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$refs['inputFile'].reset()
        this.$refs['inputFile1'].reset()
        this.$refs['inputFile2'].reset()
        this.$refs['inputFile3'].reset()
        this.$refs['inputFile4'].reset()
        this.$refs['inputFile5'].reset()
        this.$refs['inputFile6'].reset()
        this.$refs['inputFile7'].reset()
        this.$refs['inputFile8'].reset()
        this.$root.$emit('refreshMedia')
        
      }
    },

    deleteRegister(index) {
      this.fileData.register_image.splice(index,1)
      this.attachments.register_image.splice(index,1)
    },
    
    deleteMenuAdsMedia(index) {
      this.fileData.menu_ads_media.splice(index,1)
      this.attachments.menu_ads_media.splice(index,1)
    },
    
    deleteAttachCenter1(index) {
      this.fileData.center_room_media_1_url.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.center_room_media_1_url.splice(index,1)
    },
    
    deleteAttachCenter2(index) {
      this.fileData.center_room_media_2_url.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.center_room_media_2_url.splice(index,1)
    },
    
    deleteAttachEntertaiment1(index) {
      this.fileData.entertaiment_room_media_1_url.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.entertaiment_room_media_1_url.splice(index,1)
    },
    
    deleteAttachEntertaiment1Ocbc(index) {
      this.fileData.entertaiment_room_media_1_ocbc_url.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.entertaiment_room_media_1_ocbc_url.splice(index,1)
    },

    deleteAttachEntertaiment2(index) {
      this.fileData.entertaiment_room_media_2_url.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.entertaiment_room_media_2_url.splice(index,1)
    },
    
    deleteAttachHo(index) {
      this.fileData.hangout_room_mini_booth_video.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.hangout_room_mini_booth_video.splice(index,1)
    },
    
    deleteMusic(index) {
      this.fileData.entertaiment_room_music_api.splice(index,1)
      this.deletedIndexAttachmentCenter1.push(index)
      this.attachments.entertaiment_room_music_api.splice(index,1)
    },

    // E catallogue
    deleteEcatalogueAttachCenter1(index) {
      this.fileData.center_room_media_1_url_e_catalogue.splice(index,1)
      this.attachments.center_room_media_1_url_e_catalogue.splice(index,1)
    },
    
    deleteEcatalogueAttachCenter2(index) {
      this.fileData.center_room_media_2_url_e_catalogue.splice(index,1)
      this.attachments.center_room_media_2_url_e_catalogue.splice(index,1)
    },
    
    deleteEcatalogueAttachEntertaiment1(index) {
      this.fileData.entertaiment_room_media_1_url_e_catalogue.splice(index,1)
      this.attachments.entertaiment_room_media_1_url_e_catalogue.splice(index,1)
    },

    deleteEcatalogueAttachEntertaiment1Ocbc(index) {
      this.fileData.entertaiment_room_media_1_url_e_catalogue.splice(index,1)
      this.attachments.entertaiment_room_media_1_url_e_catalogue.splice(index,1)
    },

    deleteEcatalogueAttachEntertaiment2(index) {
      this.fileData.entertaiment_room_media_2_url_e_catalogue.splice(index,1)
      this.attachments.entertaiment_room_media_2_url_e_catalogue.splice(index,1)
    },
    
    deleteEcatalogueAttachHo(index) {
      this.fileData.hangout_room_mini_booth_video_e_catalogue.splice(index,1)
      this.attachments.hangout_room_mini_booth_video_e_catalogue.splice(index,1)
    },

  },
  mounted() {
    
    this.statusOptions()
    this.default_photo_url = this.form.photo_url
    this.default_company_logo_url = this.form.company_logo_url
    
    this.uploadCenterEdited1(this.form);
    this.uploadCenterEdited2(this.form);
    this.uploadEntertaimentEdited1(this.form);
    this.uploadEntertaimentEdited2(this.form);
    this.uploadMiniBoothEdited(this.form);
    
  },

  watch:{
    form: function (newVal, oldVal) {

    },
  },

  computed: {
    photoCpt() {
      return this.photo_url == null
        ? this.default_photo_url
        : this.photo_url;
    },
    logoCpt() {
      return this.company_logo_url == null
        ? this.default_company_logo_url
        : this.company_logo_url;
    },

    formatedMonthlyOmset: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.monthly_omset = newValue.toLocaleString('id-ID')
          this.form.monthly_omset = newValue
        } else {
          this.monthly_omset = 0
        }
      },
      get() {
        return this.monthly_omset.toLocaleString('id-ID')
      }
    },
  },
  
};
</script>

<style scoped>
.bg-gray{
  background-color: #cbe4f7 !important;
}

.image-input-wrapper-thumbnail {
  width: 232px !important;
  height: 232px !important;
}

.image-input-wrapper-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper-header {
  width: 280px !important;
  height: 120px !important;
}

.image-input-wrapper-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.btn-option{
  padding:7px;
}

.img-prev{
  max-width: 220px;
  height: auto;
}
.border-ckEditor-muted{
  border: 1px solid #E4E6EF !important;
}

/* ck editor */

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor_editable-container .ck-editor_editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
