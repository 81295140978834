<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <!-- start media 1 -->
        <b-form-group
          id="input-group-center-room"
          label="Media 1 Ruang Tengah:"
          label-for="input-center-room"
         
        >
        <div class="row">
          <div class="col-md-9 pb-0 mb-0">
            <b-form-file
              v-if="uploadType.center1 == 'file'"
              drop-placeholder="Lepaskan File disini..."
              @change="uploadCenter1"
            ></b-form-file>

            <b-form-input
              v-if="uploadType.center1 == 'url'"
              id="input-center-room"
              v-model="form.center_room_media_1_url"
              placeholder="Tautan Media"
            ></b-form-input>
          </div>
          <div class="col-md-3 pb-0 mb-0">
            <b-form-group>
              <b-input-group>
                <div class="input-group-prepend">
                  <b-button @click="btnUploadCenter1Link" class="btn btn-option" :variant="upload.centerLink">Tautan</b-button>
                </div>
                <div class="input-group-append">
                  <b-button @click="btnUploadCenter1Upload" class="btn btn-option" :variant="upload.centerUpload">Unggah</b-button>
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
          <small class="text-danger">{{ error.center_room_media_1_url }}</small>
        </b-form-group>
        <!-- end media 1 -->
        
        <!-- start media 2 -->
        <b-form-group
          id="input-group-center-room"
          label="Media 2 Ruang Tengah:"
          label-for="input-center-room"
         
        >
        <div class="row">
          <div class="col-md-9 pb-0 mb-0">
            <b-form-file
              v-if="uploadType.center2 == 'file'"
              drop-placeholder="Lepaskan File disini..."
              @change="uploadCenter2"
            ></b-form-file>

            <b-form-input
              v-if="uploadType.center2 == 'url'"
              id="input-center-room"
              v-model="form.center_room_media_2_url"
              placeholder="Tautan Media"
            ></b-form-input>
          </div>
          <div class="col-md-3 pb-0 mb-0">
            <b-form-group>
              <b-input-group>
                <div class="input-group-prepend">
                  <b-button @click="btnUploadCenter2Link" class="btn btn-option" :variant="upload.center2Link">Tautan</b-button>
                </div>
                <div class="input-group-append">
                  <b-button @click="btnUploadCenter2Upload" class="btn btn-option" :variant="upload.center2Upload">Unggah</b-button>
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
          <small class="text-danger">{{ error.center_room_media_2_url }}</small>
        </b-form-group>
        <!-- end media 2 -->

        <!-- start entertaiment 1 -->
        <b-form-group
          id="input-group-entertaiment-room"
          label="Media 1 Ruang Hiburan:"
          label-for="input-entertaiment-room"
        >
        <div class="row">
          <div class="col-md-9 pb-0 mb-0">
            <b-form-file
              v-if="uploadType.entertaiment == 'file'"
              drop-placeholder="Lepaskan File disini..."
              @change="uploadEntertaiment1"
            ></b-form-file>

            <b-form-input
              v-if="uploadType.entertaiment == 'url'"
              id="input-entertaiment-room"
              v-model="form.entertaiment_room_media_1_url"
              placeholder="Tautan Media"
            ></b-form-input>
          </div>
          <div class="col-md-3 pb-0 mb-0">
            <b-form-group>
              <b-input-group>
                <div class="input-group-prepend">
                  <b-button @click="btnUploadEntertaimentLink" class="btn btn-option" :variant="upload.entertaimentLink">Tautan</b-button>
                </div>
                <div class="input-group-append">
                  <b-button @click="btnUploadEntertaimentUpload" class="btn btn-option" :variant="upload.entertaimentUpload">Unggah</b-button>
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
          <small class="text-danger">{{ error.entertaiment_room_media_1_url }}</small>
        </b-form-group>
        <!-- end entertaiment 1 -->

        <!-- start entertaiment 1 -->
        <b-form-group
          id="input-group-entertaiment-room"
          label="Media 2 Ruang Hiburan:"
          label-for="input-entertaiment-room"
        >
        <div class="row">
          <div class="col-md-9 pb-0 mb-0">
            <b-form-file
              v-if="uploadType.entertaiment2 == 'file'"
              drop-placeholder="Lepaskan File disini..."
              @change="uploadEntertaiment2"
            ></b-form-file>

            <b-form-input
              v-if="uploadType.entertaiment2 == 'url'"
              id="input-entertaiment-room"
              v-model="form.entertaiment_room_media_2_url"
              placeholder="Tautan Media"
            ></b-form-input>
          </div>
          <div class="col-md-3 pb-0 mb-0">
            <b-form-group>
              <b-input-group>
                <div class="input-group-prepend">
                  <b-button @click="btnUploadEntertaiment2Link" class="btn btn-option" :variant="upload.entertaiment2Link">Tautan</b-button>
                </div>
                <div class="input-group-append">
                  <b-button @click="btnUploadEntertaiment2Upload" class="btn btn-option" :variant="upload.entertaiment2Upload">Unggah</b-button>
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
          <small class="text-danger">{{ error.entertaiment_room_media_2_url }}</small>
        </b-form-group>
        <!-- end entertaiment 2 -->

        <!-- start hangout video -->
        <b-form-group
          id="input-group-mini-booth-room"
          label="Mini Booth Video:"
          label-for="input-mini-booth-room"
        >
        <div class="row p-0">
          <div class="col-md-9 pb-0 mb-0">
            <b-form-file
              v-if="uploadType.hangoutVideo == 'file'"
              drop-placeholder="Lepaskan File disini..."
              @change="uploadMiniBoothVideo"
            ></b-form-file>

            <b-form-input
              v-if="uploadType.hangoutVideo == 'url'"
              id="input-mini-booth-room"
              v-model="form.hangout_room_mini_booth_video"
              placeholder="Tautan Video"
            ></b-form-input>
          </div>
          <div class="col-md-3 pb-0 mb-0">
            <b-form-group>
              <b-input-group>
                <div class="input-group-prepend">
                  <b-button @click="btnUploadhangoutLink" class="btn btn-option" :variant="upload.hangoutVideoLink">Tautan</b-button>
                </div>
                <div class="input-group-append">
                  <b-button @click="btnUploadhangoutUpload" class="btn btn-option" :variant="upload.hangoutVideoUpload">Unggah</b-button>
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
          <small class="text-danger">{{ error.hangout_room_mini_booth_video }}</small>
        </b-form-group>
        <!-- end hangout video -->

         <b-form-group
          id="input-group-music-api"
          label="Tautan URL musik API:"
          label-for="input-music-api"
        >
          <b-form-input
            id="input-phone"
            v-model="form.entertaiment_room_music_api"
            placeholder="Tautan URL musik API"
          ></b-form-input>
          <small class="text-danger">{{ error.entertaiment_room_music_api }}</small>
        </b-form-group>

        <!-- start hangout video -->
        <b-form-group
          id="input-group-mini-booth-room"
          label="Mini Booth PDF Brosur:"
          label-for="input-mini-booth-room"
        >
            <b-form-file
              drop-placeholder="Lepaskan File disini..."
              @change="uploadMiniBoothBrochure"
            ></b-form-file>
          <small class="text-danger">{{ error.hangout_room_mini_booth_pdf_brochure }}</small>
        </b-form-group>
        <!-- end hangout video -->

         <b-form-group
          id="input-group-microsite"
          label="Tautan URL Microsite:"
          label-for="input-microsite"
        >
          <b-form-input
            id="input-phone-microsite"
            v-model="form.hangout_room_mini_booth_pdf_microsite_url"
            placeholder="Tautan URL microsite"
          ></b-form-input>
          <small class="text-danger">{{ error.hangout_room_mini_booth_pdf_microsite_url }}</small>
        </b-form-group>

      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  data() {
    return {
      // errors
      error: {
        center_room_media_1_url :"",
        center_room_media_2_url:"",
        entertaiment_room_media_1_url:"",
        entertaiment_room_media_2_url:"",
        entertaiment_room_music_api:"",
        hangout_room_mini_booth_video:"",
        hangout_room_mini_booth_pdf_brochure:"",
        hangout_room_mini_booth_pdf_microsite_url:"",
      },

      // btn input type attr
      upload:{
        // link
        centerLink : "primary",
        center2Link : "primary",
        entertaimentLink : "primary",
        entertaiment2Link : "primary",
        hangoutVideoLink : "primary",
        
        // upload
        centerUpload : "outline-primary",
        center2Upload : "outline-primary",
        entertaimentUpload : "outline-primary",
        entertaiment2Upload : "outline-primary",
        hangoutVideoUpload : "outline-primary",
      },
    
      uploadType:{
        center1:"url",
        center2:"url",
        entertaiment:"url",
        entertaiment2:"url",
        hangoutVideo:"url",
      },

      // options
      formData : new FormData(),
      roles:[],
      
    };
  },
  methods: {
    // media center 1
    btnUploadCenter1Link(){
      this.upload.centerLink = "primary"
      this.upload.centerUpload = "outline-primary"
      this.uploadType.center1 = "url"
      this.form.center_room_media_1_url_type = "link"
    },
    btnUploadCenter1Upload(){
      this.upload.centerUpload = "primary"
      this.upload.centerLink = "outline-primary"
      this.uploadType.center1 = "file"
      this.form.center_room_media_1_url_type = "file"
    },

    // media center 2
    btnUploadCenter2Link(){
      this.upload.center2Link = "primary"
      this.upload.center2Upload = "outline-primary"
      this.uploadType.center2 = "url"
      this.form.center_room_media_2_url_type = "link"
    },
    btnUploadCenter2Upload(){
      this.upload.center2Upload = "primary"
      this.upload.center2Link = "outline-primary"
      this.uploadType.center2 = "file"
      this.form.center_room_media_2_url_type = "file"
    },
    
    // entertaiment 1
    btnUploadEntertaimentLink(){
      this.upload.entertaimentLink = "primary"
      this.upload.entertaimentUpload = "outline-primary"
      this.uploadType.entertaiment = "url",
      this.form.entertaiment_room_media_1_url_type = "link"
    },
    btnUploadEntertaimentUpload(){
      this.upload.entertaimentUpload = "primary"
      this.upload.entertaimentLink = "outline-primary"
      this.uploadType.entertaiment = "file"
      this.form.entertaiment_room_media_1_url_type = "file"
    },
    
    // entertaiment 2
    btnUploadEntertaiment2Link(){
      this.upload.entertaiment2Link = "primary"
      this.upload.entertaiment2Upload = "outline-primary"
      this.uploadType.entertaiment2 = "url"
      this.form.entertaiment_room_media_2_url_type = "link"
    },
    btnUploadEntertaiment2Upload(){
      this.upload.entertaiment2Upload = "primary"
      this.upload.entertaiment2Link = "outline-primary"
      this.uploadType.entertaiment2 = "file"
      this.form.entertaiment_room_media_2_url_type = "file"
    },

    // hangout mini booth video
    btnUploadhangoutLink(){
      this.upload.hangoutVideoLink = "primary"
      this.upload.hangoutVideoUpload = "outline-primary"
      this.uploadType.hangoutVideo = "url"
      this.form.hangout_room_mini_booth_video_type = "link"
    },
    btnUploadhangoutUpload(){
      this.upload.hangoutVideoUpload = "primary"
      this.upload.hangoutVideoLink = "outline-primary"
      this.uploadType.hangoutVideo = "file"
      this.form.hangout_room_mini_booth_video_type = "file"
    },

    // file upload
    uploadCenter1(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("center_room_media_1_url[]", event.target.files[a]);
      }
    },

    uploadCenter2(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("center_room_media_2_url[]", event.target.files[a]);
      }
    },
    
    uploadEntertaiment1(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_1_url[]", event.target.files[a]);
      }
      
    },
    
    uploadEntertaiment2(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("entertaiment_room_media_2_url[]", event.target.files[a]);
      }
    },

    uploadMiniBoothVideo(event) {
      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("hangout_room_mini_booth_video[]", event.target.files[a]);
      }
    },

    uploadMiniBoothBrochure(event) {
      this.formData.append("hangout_room_mini_booth_pdf_brochure", event.target.files[0]);
    },

    validationMessage() {
      Swal.fire({
        title: "Gagal",
        text: "Harap periksa kembali form",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async statusOptions() {
      let response = await module.setTreeSelect("api/member-roles");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Pilih Peran",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // console.log(this.form);
      for (const [key, value] of Object.entries(this.form)) {
          this.formData.append(key, value);
      }

      // Make Request
      // this.form.photo_url = this.photo_url
      // this.form.company_logo_url = this.company_logo_url

      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/promotion-medias");
      }
    },
  },
  mounted() {
    this.statusOptions()
    console.log(this.form);
    // this.default_photo_url = this.form.photo_url
    // this.default_company_logo_url = this.form.company_logo_url
  },

  watch:{
    // form: function (newVal, oldVal) {
    //   if (this.purpose == "edit") {
        
    //     this.default_photo_url = this.form.photo_url
    //     this.default_company_logo_url = this.form.company_logo_url


    //   }
    // },
  },

  computed: {
    // photoCpt() {
    //   return this.photo_url == null
    //     ? this.default_photo_url
    //     : this.photo_url;
    // },
    // logoCpt() {
    //   return this.company_logo_url == null
    //     ? this.default_company_logo_url
    //     : this.company_logo_url;
    // },

    // formatedMonthlyOmset: {
    //   set(newValue) {
    //     newValue = parseInt(newValue.replace(/\D/g, ''), 10)
    //     if (!Number.isNaN(newValue)) {
    //       this.monthly_omset = newValue.toLocaleString('id-ID')
    //       this.form.monthly_omset = newValue
    //     } else {
    //       this.monthly_omset = 0
    //     }
    //   },
    //   get() {
    //     return this.monthly_omset.toLocaleString('id-ID')
    //   }
    // },
  },
  
};
</script>

<style scoped>
.image-input-wrapper-thumbnail {
  width: 232px !important;
  height: 232px !important;
}

.image-input-wrapper-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper-header {
  width: 280px !important;
  height: 120px !important;
}

.image-input-wrapper-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.btn-option{
  padding:7px;
}
</style>