<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <!-- <div class="card card-custom"> -->
        <!-- <div class="card-body p-0"> -->
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                
                <!-- add form -->
                <Form
                  :form="form"
                  :title="'Pengaturan Media Promosi'"
                  :route="route"
                  :purpose="purpose"
                  v-if="purpose == 'add' && dataLoaded"
                />

                <!-- edit form -->
                <FormEdit
                  :form="form"
                  :title="'Pengaturan Media Promosi'"
                  :route="route"
                  :purpose="purpose"
                  :fileData="fileData"
                  :attachments="attachment"
                  v-if="purpose == 'edit'"
                />

                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        <!-- </div> -->
        <!--end: Wizard-->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormEdit from "@/view/components/promotion-medias/FormEdit.vue";
import Form from "@/view/components/promotion-medias/Form.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    Form,
    FormEdit,

  },
  data() {
    return {
      form: {},
      dataLoaded: false,
      obj:{},
      purpose:"add",
      route : "",

      // form data
      formData: {
        center_room_media_1_url :"",
        center_room_media_1_url_type:"url",
        center_room_media_2_url:"",
        center_room_media_2_url_type:"url",
        entertaiment_room_media_1_url:"",
        entertaiment_room_media_1_url_type:"url",
        entertaiment_room_media_2_url:"",
        entertaiment_room_media_2_url_type:"url",
        entertaiment_room_music_api:"",
        hangout_room_mini_booth_video:"",
        hangout_room_mini_booth_video_type:"url",
        hangout_room_mini_booth_pdf_brochure:"",
        hangout_room_mini_booth_pdf_microsite_url:"",
      },
      
      //attachment
      attachmentData:{},
      attachment: {},
      fileData: {
        center_room_media_1_url :[],
        center_room_media_1_url_e_catalogue :[],
        center_room_media_2_url:[],
        center_room_media_2_url_e_catalogue:[],
        entertaiment_room_media_1_url:[],
        entertaiment_room_media_1_ocbc_url:[],
        entertaiment_room_media_1_url_e_catalogue:[],
        entertaiment_room_media_2_url:[],
        entertaiment_room_media_2_url_e_catalogue:[],
        entertaiment_room_music_api:[],
        hangout_room_mini_booth_video:[],
        hangout_room_mini_booth_video_e_catalogue:[],
        register_image:[],
        menu_ads_media:[]
      },
      urlImage:{
        center_room_media_1_url :[],
        center_room_media_2_url:[],
        entertaiment_room_media_1_url:[],
        entertaiment_room_media_2_url:[],
        hangout_room_mini_booth_video:[],
      } 
    };
  },
  methods:{
    async get() {
      // this.form = await module.get('api/promotion-medias/' + this.$route.params.id)
      let data = await module.get('api/promotion-medias')
      
      // add new
      if (data.length == 0) {
        // Redirect To List
        this.form = {
        center_room_media_1_url :"",
        center_room_media_1_url_type:"url",
        center_room_media_2_url:"",
        center_room_media_2_url_type:"url",
        entertaiment_room_media_1_url:"",
        entertaiment_room_media_1_url_type:"url",
        entertaiment_room_media_2_url:"",
        entertaiment_room_media_2_url_type:"url",
        entertaiment_room_music_api:"",
        hangout_room_mini_booth_video:"",
        hangout_room_mini_booth_video_type:"url",
        hangout_room_mini_booth_pdf_brochure:"",
        hangout_room_mini_booth_pdf_microsite_url:"",
        },

        this.purpose = "add"
        this.route = "api/promotion-medias"

        this.dataLoaded = true

      } else {
        this.form = data[0]
        this.route = "api/promotion-medias/1"
        this.purpose = "edit"
        this.form['_method'] = 'put'

        // register image
        if(this.form.register_image != null){
            this.form.register_image.forEach((el,index) => {
              // get name
              let name = el.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:el,
                file_ext:ext
              }
              this.fileData.register_image.push(obj)
          });

          this.attachment.register_image =  this.form.register_image
        }

        // menu ads media
        if(this.form.menu_ads_media != null){
            this.form.menu_ads_media.forEach((el,index) => {
              // get name
              let name = el.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:el,
                file_ext:ext
              }
              this.fileData.menu_ads_media.push(obj)
          });

          this.attachment.menu_ads_media =  this.form.menu_ads_media
        }

        // start media 
        
        if(this.form.center_room_media_1_url_data != null && this.form.center_room_media_1_url_type != 'url'){
            this.form.center_room_media_1_url_data.forEach((el,index) => {
              // get name
              let name = el.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:el,
                file_ext:ext
              }
              this.fileData.center_room_media_1_url.push(obj)
          });

          this.attachment.center_room_media_1_url =  this.form.center_room_media_1_url_data
        }

        // e catalogue center 1
        if(this.form.center_room_media_1_url_e_catalogue != null){
            this.form.center_room_media_1_url_e_catalogue.forEach((elEcat,index) => {
              // get name
              let name = elEcat.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elEcat,
                file_ext:ext
              }
              this.fileData.center_room_media_1_url_e_catalogue.push(obj)
          });

          this.attachment.center_room_media_1_url_e_catalogue =  this.form.center_room_media_1_url_e_catalogue
        }
        
        if(this.form.center_room_media_2_url_data != null && this.form.center_room_media_2_url_type != 'url'){
          this.form.center_room_media_2_url_data.forEach((val,index) => {
             // get name
              let name = val.split("__________")
              name = name[name.length-1]

               // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:val,
                file_ext:ext
                
              }

              this.fileData.center_room_media_2_url.push(obj)
              
          });
              this.attachment.center_room_media_2_url = this.form.center_room_media_2_url_data
        }

        // e catalogue center 2
        if(this.form.center_room_media_2_url_e_catalogue != null){
            this.form.center_room_media_2_url_e_catalogue.forEach((elEcat,index) => {
              // get name
              let name = elEcat.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elEcat,
                file_ext:ext
              }
              this.fileData.center_room_media_2_url_e_catalogue.push(obj)
          });

          this.attachment.center_room_media_2_url_e_catalogue =  this.form.center_room_media_2_url_e_catalogue
        }

        if(this.form.entertaiment_room_media_1_url_data != null && this.form.entertaiment_room_media_1_url_type != 'url'){
          this.form.entertaiment_room_media_1_url_data.forEach((valEnt,index) => {
             // get name
              let name = valEnt.split("__________")
              name = name[name.length-1]

               // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url: valEnt,
                file_ext:ext
              }
              this.fileData.entertaiment_room_media_1_url.push(obj)
              
          });
              this.attachment.entertaiment_room_media_1_url = this.form.entertaiment_room_media_1_url_data
        }

        // e catalogue entertaiment 1
        if(this.form.entertaiment_room_media_1_url_e_catalogue != null){
            this.form.entertaiment_room_media_1_url_e_catalogue.forEach((elEcat,index) => {
              // get name
              let name = elEcat.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elEcat,
                file_ext:ext
              }
              this.fileData.entertaiment_room_media_1_url_e_catalogue.push(obj)
          });

          this.attachment.entertaiment_room_media_1_url_e_catalogue =  this.form.entertaiment_room_media_1_url_e_catalogue
        }

        // entertaiment 1 OCBC
        if(this.form.entertaiment_room_media_1_ocbc_url_data != null && this.form.entertaiment_room_media_1_ocbc_url_type != 'url'){
          this.form.entertaiment_room_media_1_ocbc_url_data.forEach((valEnt,index) => {
             // get name
              let name = valEnt.split("__________")
              name = name[name.length-1]

               // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url: valEnt,
                file_ext:ext
              }
              this.fileData.entertaiment_room_media_1_ocbc_url.push(obj)
              
          });
              this.attachment.entertaiment_room_media_1_ocbc_url = this.form.entertaiment_room_media_1_ocbc_url_data
        }

        // e catalogue entertaiment 1 OCBC
        if(this.form.entertaiment_room_media_1_ocbc_url_e_catalogue != null){
            this.form.entertaiment_room_media_1_ocbc_url_e_catalogue.forEach((elEcat,index) => {
              // get name
              let name = elEcat.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elEcat,
                file_ext:ext
              }
              this.fileData.entertaiment_room_media_1_ocbc_url_e_catalogue.push(obj)
          });

          this.attachment.entertaiment_room_media_1_ocbc_url_e_catalogue =  this.form.entertaiment_room_media_1_ocbc_url_e_catalogue
        }
        
        if(this.form.entertaiment_room_media_2_url_data != null && this.form.entertaiment_room_media_2_url_type != 'url'){
          this.form.entertaiment_room_media_2_url_data.forEach((valEnt2,index) => {
            // get name
              let name = valEnt2.split("__________")
              name = name[name.length-1]

               // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url: valEnt2,
                file_ext:ext
              }
              this.fileData.entertaiment_room_media_2_url.push(obj)
              
          });
              this.attachment.entertaiment_room_media_2_url =  this.form.entertaiment_room_media_2_url_data
        }

        // e catalogue entertaiment 2
        if(this.form.entertaiment_room_media_2_url_e_catalogue != null){
            this.form.entertaiment_room_media_2_url_e_catalogue.forEach((elEcat,index) => {
              // get name
              let name = elEcat.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elEcat,
                file_ext:ext
              }
              this.fileData.entertaiment_room_media_2_url_e_catalogue.push(obj)
          });

          this.attachment.entertaiment_room_media_2_url_e_catalogue =  this.form.entertaiment_room_media_2_url_e_catalogue
        }
        
        if(this.form.hangout_room_mini_booth_video_data != null && this.form.hangout_room_mini_booth_video_type != 'url'){
          this.form.hangout_room_mini_booth_video_data.forEach((valHo,index) => {
             // get name
              let name = valHo.split("__________")
              name = name[name.length-1]

               // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:valHo,
                file_ext:ext
              }
              this.fileData.hangout_room_mini_booth_video.push(obj)
              
          });
              this.attachment.hangout_room_mini_booth_video = this.form.hangout_room_mini_booth_video_data
        }

        // e catalogue HO 2
        if(this.form.hangout_room_mini_booth_video_e_catalogue != null){
            this.form.hangout_room_mini_booth_video_e_catalogue.forEach((elEcat,index) => {
              // get name
              let name = elEcat.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elEcat,
                file_ext:ext
              }
              this.fileData.hangout_room_mini_booth_video_e_catalogue.push(obj)
          });

          this.attachment.hangout_room_mini_booth_video_e_catalogue =  this.form.hangout_room_mini_booth_video_e_catalogue
        }

        // Music
        if(this.form.entertaiment_room_music_api_data != null){
            this.form.entertaiment_room_music_api_data.forEach((elMus,index) => {
              // get name
              let name = elMus.split("__________")
              name = name[name.length-1]

              // get ext
              let ext = name.split(".")
              ext = ext[ext.length-1]

              // push into object
              let obj = {
                file_name: name,
                file_url:elMus,
                file_ext:ext
              }
              this.fileData.entertaiment_room_music_api.push(obj)
          });

          this.attachment.entertaiment_room_music_api =  this.form.entertaiment_room_music_api
        }

        if(this.form.center_room_media_1_url_type == 'file'){
          this.form.center_room_media_1_url = ""
        }else{
          if(this.form.center_room_media_1_url == 'null' || this.form.center_room_media_1_url == null){
            this.form.center_room_media_1_url = ""
          }
        }

        if(this.form.center_room_media_2_url_type == 'file'){
          this.form.center_room_media_2_url = ""
        }else{
          if(this.form.center_room_media_2_url == 'null' || this.form.center_room_media_2_url == null){
            this.form.center_room_media_2_url = ""
          }
        }

        if(this.form.entertaiment_room_media_1_url_type == 'file'){
          this.form.entertaiment_room_media_1_url = ""
        }else{
          if(this.form.entertaiment_room_media_1_url == 'null' || this.form.entertaiment_room_media_1_url == null){
            this.form.entertaiment_room_media_1_url = ""
          }
        }

        if(this.form.entertaiment_room_media_2_url_type == 'file'){
          this.form.entertaiment_room_media_2_url = ""
        }else{
          if(this.form.entertaiment_room_media_2_url == 'null' || this.form.entertaiment_room_media_2_url == null){
            this.form.entertaiment_room_media_2_url = ""
          }
        }

        if(this.form.hangout_room_mini_booth_video_type == 'file'){
          this.form.hangout_room_mini_booth_video = ""
        }else{
          if(this.form.hangout_room_mini_booth_video == 'null' || this.form.hangout_room_mini_booth_video == null){
            this.form.hangout_room_mini_booth_video = ""
          }
        }
        
        if(this.form.hangout_room_mini_booth_pdf_brochure_type == 'file'){
          this.form.hangout_room_mini_booth_pdf_brochure = ""
        }else{
          if(this.form.hangout_room_mini_booth_pdf_brochure == 'null' || this.form.hangout_room_mini_booth_pdf_brochure == null){
            this.form.hangout_room_mini_booth_pdf_brochure = ""
          }
        }

        if(this.form.entertaiment_room_music_api == 'null' || this.form.entertaiment_room_music_api == null){
            this.form.entertaiment_room_music_api = "";
        }
        
        if(this.form.hangout_room_mini_booth_pdf_microsite_url == 'null' || this.form.hangout_room_mini_booth_pdf_microsite_url == null){
            this.form.hangout_room_mini_booth_pdf_microsite_url = "";
        }

        console.log(this.fileData,"dataaaaaa");

        // this.dataLoaded = true
      }
    }
  },
  mounted() {
    this.$root.$on('refreshMedia',()=>{
      //attachment
      this.attachmentData = {},
      this.attachment = {},
      
        this.fileData.center_room_media_1_url  = [],
        this.fileData.center_room_media_1_url_e_catalogue  = [],
        this.fileData.center_room_media_2_url = [],
        this.fileData.center_room_media_2_url_e_catalogue =[],
        this.fileData.entertaiment_room_media_1_url = [],
        this.fileData.entertaiment_room_media_1_ocbc_url = [],
        this.fileData.entertaiment_room_media_1_url_e_catalogue = [],
        this.fileData.entertaiment_room_media_2_url = [],
        this.fileData.entertaiment_room_media_2_url_e_catalogue = [],
        this.fileData.hangout_room_mini_booth_video = [],
        this.fileData.hangout_room_mini_booth_video_e_catalogue = [],
        this.fileData.register_image = [],
        this.fileData.menu_ads_media = []
      
      this.get()
    })
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pengaturan Media Promosi", route: "/promotion-medias" }
    ]);

    this.get();
  },
};
</script>